import { useGetBrandVoicesInfinite } from 'features/brand-voice/useGetBrandVoicesInfinite';

type QueryOptions = Parameters<typeof useGetBrandVoicesInfinite>[0];

export const useGetBrandVoicesInfiniteSelectOptions = (queryOptions?: QueryOptions) =>
  useGetBrandVoicesInfinite({
    ...queryOptions,
    size: 5,
    sort: 'updated_at'
  });

import { AiWriterProject, AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import {
  aiWriterTextInspirationFromApi,
  aiWriterTextInspirationToApi
} from 'features/aiWriter/store/utils/mappers/aiWriterTextInspirationsMapper';
import {
  aiWriterTextToApi,
  getProjectShortText
} from 'features/aiWriter/store/utils/mappers/aiWriterTextMappers';
import { ApiAiWriterProject, ApiAiWriterProjectWithShortText } from 'services/api/aiWriter/types';

export const aiWriterProjectFromApi = (project: ApiAiWriterProject): AiWriterProject => ({
  id: project.id,
  hash: project.hash,
  embeddingModelId: project.embedding_model_id,
  name: project.name,
  authorId: project.author_id ?? 0,
  authorName: project.author_name ?? '',
  text: JSON.parse(project.text),
  short_text: project.short_text ?? '',
  generateTextConfig: {
    outputType: project.output_type,
    audienceId: project.audience_id,
    brand: project.brand,
    keywords: project.keywords,
    text: project.config_text,
    systemTonality: project.tonality,
    userTonality: [],
    personalityId: project.personality_id,
    brandVoiceId: project.brand_voice_id
  },
  textInspirations: project.text_inspirations?.map(aiWriterTextInspirationFromApi) ?? [],
  updatedAt: project.updated_at,
  isPublic: Boolean(project.is_public),
  isLoading: false,
  images: [],
  imagesNotFound: false,
  sharingPermission: project.sharing_permission,
  tags: project.tags ?? []
});

export const aiWriterProjectWithShortTextFromApi = (
  project: ApiAiWriterProjectWithShortText
): AiWriterProjectWithShortText => ({
  id: project.id,
  hash: project.hash,
  embeddingModelId: project.embedding_model_id,
  name: project.name,
  authorId: project.author_id ?? 0,
  authorName: project.author_name ?? '',
  short_text: project.short_text ?? '',
  generateTextConfig: {
    outputType: project.output_type,
    audienceId: project.audience_id,
    brand: project.brand,
    keywords: project.keywords,
    text: project.config_text,
    systemTonality: project.tonality,
    userTonality: [],
    personalityId: project.personality_id,
    brandVoiceId: project.brand_voice_id
  },
  textInspirations: project.text_inspirations?.map(aiWriterTextInspirationFromApi) ?? [],
  updatedAt: project.updated_at,
  isPublic: Boolean(project.is_public),
  isLoading: false,
  images: [],
  imagesNotFound: false,
  sharingPermission: project.sharing_permission,
  tags: project.tags ?? []
});

export const aiWriterProjectToApi = (project: AiWriterProject): ApiAiWriterProject => ({
  id: project.id,
  hash: project.hash,
  embedding_model_id: project.embeddingModelId,
  name: project.name,
  text: aiWriterTextToApi(project.text),
  short_text: project.short_text ?? getProjectShortText(project.text),
  output_type: project.generateTextConfig.outputType,
  audience_id: project.generateTextConfig?.audienceId,
  brand: project.generateTextConfig.brand ?? '',
  keywords: project.generateTextConfig?.keywords ?? '',
  config_text: project.generateTextConfig?.text ?? '',
  text_inspirations: project.textInspirations?.map(aiWriterTextInspirationToApi) ?? null,
  tonality: project.generateTextConfig?.systemTonality ?? [],
  updated_at: project.updatedAt,
  is_public: Boolean(project.isPublic),
  sharing_permission: project.sharingPermission,
  author_id: project.authorId,
  author_name: project.authorName,
  personality_id: project.generateTextConfig.personalityId,
  brand_voice_id: project.generateTextConfig.brandVoiceId,
  tags: project.tags ?? []
});

import { useEffect } from 'react';

interface TrackingOptions {
  onPopupOpen?: () => void;
}

export const useLanguageToolTracking = (options: TrackingOptions = {}) => {
  useEffect(() => {
    const { onPopupOpen } = options;

    // Track popup openings through DOM mutations
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // Assumption: If a new node is added and it's a lt-card with lt-comp-card-base as its first child,
        //             somebody clicked on a word and the popup opened.
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === 1) {
            const element = node as Element;

            if (
              element.tagName.toLowerCase() === 'lt-card' &&
              element.firstChild?.nodeType === 1 &&
              (element.firstChild as Element).tagName.toLowerCase() === 'lt-comp-card-base'
            ) {
              onPopupOpen?.();
            }
          }
        });
      });
    });

    // Start observing
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [options]);
}; 
import {
  CircularProgress,
  Divider,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';
import { InfiniteDropdownIntersectionObserver } from 'components/InfiniteDropdownIntersectionObserver';
import {
  menuOpeningBottomLeftProps,
  menuOpeningTopLeftProps
} from 'components/personality/PersonalityChatSelector';
import { wiggleAnimation } from 'components/tour/utils/wiggleAnimation';
import { EMPTY_OPTION } from 'features/brand-voice/components/AutocompleteDropdownMenuContent';
import { BrandVoiceMenuItemContent } from 'features/brand-voice/components/BrandVoiceMenuItemContent';
import { BrandVoiceSelectAdornment } from 'features/brand-voice/components/BrandVoiceSelectAdornment';
import { BrandVoiceSelectRenderValue } from 'features/brand-voice/components/BrandVoiceSelectRenderValue';
import { CreateBrandVoiceMenuItemContent } from 'features/brand-voice/components/CreateBrandVoiceMenuItemContent';
import { useBrandVoiceAutocompleteData } from 'features/brand-voice/useBrandVoiceAutocompleteData';
import { useRedirectToBrandVoiceCreation } from 'features/brand-voice/useRedirectToBrandVoiceCreation';
import { useSetDefaultBrandVoiceMutation } from 'features/brand-voice/useSetDefaultBrandVoiceMutation';
import { useEffect, useId, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

type Props = Pick<SelectProps, 'onClose' | 'onClick' | 'onMouseDown'> & {
  selectedBrandVoiceId?: string;
  targetLanguage?: string;
  targetCountry?: string;
  onBrandVoiceChange?: (brandVoiceId: string | null) => void | boolean | Promise<void | boolean>;
  menuOpeningDirection?: 'top' | 'bottom';
};

export const ExtendedChatInputBrandVoiceSelect = ({
  selectedBrandVoiceId,
  targetLanguage,
  targetCountry,
  onBrandVoiceChange,
  menuOpeningDirection,
  ...selectProps
}: Props) => {
  const selectId = useId();
  const labelId = useId();
  const translate = useTr();

  const [localSelectedBrandVoiceId, setLocalSelectedBrandVoiceId] = useState<string>(
    selectedBrandVoiceId ?? (EMPTY_OPTION.id as string)
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    setLocalSelectedBrandVoiceId(selectedBrandVoiceId ?? (EMPTY_OPTION.id as string));
  }, [selectedBrandVoiceId]);

  const { isLoading, brandVoices, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useBrandVoiceAutocompleteData({
      initialBrandVoiceId: selectedBrandVoiceId,
      targetLanguage,
      targetCountry
    });

  const { mutate: setDefaultBrandVoice } = useSetDefaultBrandVoiceMutation();

  const redirectToBrandVoiceCreation = useRedirectToBrandVoiceCreation();

  const handleCreateBrandVoiceClick = () => {
    setIsSelectOpen(false);

    redirectToBrandVoiceCreation();
  };

  const handleChange = async (event: SelectChangeEvent) => {
    const value = event.target.value;

    const brandVoiceId = value === EMPTY_OPTION.id ? null : value;

    if (onBrandVoiceChange) {
      const result = await onBrandVoiceChange?.(brandVoiceId);
      if (result === false) {
        return;
      }
    }

    setLocalSelectedBrandVoiceId(brandVoiceId ?? (EMPTY_OPTION.id as string));

    if (brandVoiceId) {
      setDefaultBrandVoice({ brandVoiceId });
    }
  };

  if (isLoading) {
    return <CircularProgress size="small" />;
  }

  return (
    <StyledSelect
      {...withTestId('brand-voice-select')}
      labelId={labelId}
      id={selectId}
      value={localSelectedBrandVoiceId ?? undefined}
      onChange={handleChange}
      inputProps={{
        // Removes the chevron icon
        IconComponent: () => null
      }}
      MenuProps={{
        ...(menuOpeningDirection === 'top' ? menuOpeningTopLeftProps : menuOpeningBottomLeftProps),
        MenuListProps: {
          sx: { maxHeight: 250 },
          component: 'ul'
        }
      }}
      renderValue={selectedId => (
        <BrandVoiceSelectRenderValue
          brandVoices={brandVoices}
          selectedId={selectedId}
          defaultMessage="-"
          onClick={() => setIsSelectOpen(prevState => !prevState)}
        />
      )}
      placeholder={translate('brand_voice.select.selection_placeholder')}
      startAdornment={
        // Note: That's NOT the selected brand voice, only default value or placeholder
        //       The selected value is rendered in renderValue()
        <BrandVoiceSelectAdornment
          isLoading={isLoading}
          selectedBrandVoice={brandVoices.find(
            brandVoice => brandVoice.id === localSelectedBrandVoiceId
          )}
          onClick={() => setIsSelectOpen(prevState => !prevState)}
        />
      }
      disabled={brandVoices.length === 0}
      {...selectProps}
      onClick={event => {
        if (!isSelectOpen) {
          setIsSelectOpen(true);
        }

        selectProps.onClick?.(event);
      }}
      open={isSelectOpen}
      onClose={event => {
        setIsSelectOpen(false);
        selectProps.onClose?.(event);
      }}
    >
      <MenuItem onClick={handleCreateBrandVoiceClick} value="create">
        <CreateBrandVoiceMenuItemContent />
      </MenuItem>

      <Divider key="divider-1" />

      <MenuItem
        key={EMPTY_OPTION.id}
        value={EMPTY_OPTION.id}
        selected={!localSelectedBrandVoiceId || localSelectedBrandVoiceId === EMPTY_OPTION.id}
      >
        <FormattedMessage id={EMPTY_OPTION.name} />
      </MenuItem>

      {brandVoices.map(brandVoice => (
        <MenuItem key={brandVoice.id ?? Math.random()} value={brandVoice.id || ''}>
          <BrandVoiceMenuItemContent option={brandVoice} />
        </MenuItem>
      ))}

      <InfiniteDropdownIntersectionObserver
        onIsInView={() => fetchNextPage()}
        isAlreadyFetching={isFetchingNextPage}
        enabled={hasNextPage}
      />
    </StyledSelect>
  );
};

const StyledSelect = styled(MuiSelect)<{ $withWiggleEffect: boolean }>`
  max-width: 160px;

  background-color: transparent;

  ${({ $withWiggleEffect }) => $withWiggleEffect && wiggleAnimation};
  animation-iteration-count: 3;

  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacings.two};

  & .MuiInputBase-input {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.small};
    // Aligns the height to the current template button style
    padding: 2px 0px;
  }

  & .MuiListItemText-primary {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  && .MuiSelect-outlined {
    padding: 5px;
  }

  & .MuiInputAdornment-root {
    height: auto;
  }

  & fieldset {
    border: none;
  }
` as typeof MuiSelect;

import Toast from 'components/toasts/Toast';
import cuid from 'cuid';
import { updateTesterProjectInBackgroundThunk } from 'features/aiTester/store/actions/project/thunks/updateTesterProjectInBackgroundThunk';
import { generateSuggestions } from 'features/aiTester/store/actions/suggestions/actions';
import { rescoreSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/rescoreSuggestionsThunk';
import getAudienceModelIdById from 'features/audiences/utils/getAudienceModelIdById';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import isValidGenerateTextConfig from 'features/textGenerator/store/isValidGenerateTextConfig';
import { fetchAndCachePromptsByCountryAndLanguage } from 'features/textGenerator/utils/useGetPromptsByCountryAndLanguage';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const generateSuggestionsThunk = (): AppThunk<void> => async (dispatch, getState) => {
  const state = getState();
  const { tester: testerState, audiences: audiencesState } = state;
  const currentTab = getCurrentTab(testerState);

  if (!currentTab) {
    return;
  }

  const { embeddingModelId, generateTextConfig, id: tabId } = currentTab;

  if (!isValidGenerateTextConfig(generateTextConfig)) {
    return;
  }

  const {
    audienceId,
    outputType,
    brand,
    keywords,
    text,
    systemTonality,
    userTonality,
    personalityId,
    brandVoiceId,
    informationList
  } = generateTextConfig;

  const audienceModelId = getAudienceModelIdById(audiencesState, audienceId);
  if (!audienceModelId) {
    return;
  }

  dispatch(generateSuggestions.request());

  const { language: modelLanguage, country: modelCountry } =
    getGetEmbeddingModelLanguageAndCountryById(state)(embeddingModelId);

  try {
    const promptConfig = await fetchAndCachePromptsByCountryAndLanguage(
      modelLanguage,
      modelCountry
    ).then(prompts => prompts.find(p => p.outputType.id === outputType));

    if (!promptConfig) {
      dispatch(generateSuggestions.failure({ tabId }));
      Toast.commonError();
      return;
    }

    const { outputs } = await generateTexts(
      { audienceModelId, outputType, nTimes: promptConfig.nTimes },
      {
        keywords,
        keywords2: brand, // #tech-debt https://app.clickup.com/t/37t6awm
        text,
        systemTonality,
        userTonality,
        personalityId,
        brandVoiceId,
        informationList
      }
    );

    const scoredTexts = outputs.map(text => ({
      id: cuid(),
      text,
      score: 0,
      textItemId: text.textItemId,
      generationId: text.generationId
    }));
    const suggestions = scoredTexts.map(scoredText => {
      return {
        ...scoredText,
        subSuggestions: [],
        text: scoredText.text.text,
        textItemId: scoredText.textItemId,
        generationId: scoredText.generationId
      };
    });

    dispatch(generateSuggestions.success({ suggestions, tabId }));
    dispatch(updateTesterProjectInBackgroundThunk());
    dispatch(rescoreSuggestionsThunk());
  } catch (e) {
    dispatch(generateSuggestions.failure({ tabId }));
  }
};

import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { Tab, Tabs } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import Title from 'features/aiWriter/AiWriterSidebar/AiWriterSidebarPanel/Title';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import { FlashScoreAnalyzeView } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import { FlashScoreFetchingIcon } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreFetchingIcon';
import { PlagiarismCheckStep } from 'features/aiWriter/AiWriterSidebar/steps/PlagiarismCheckStep';
import { SeoAnalysisStep } from 'features/aiWriter/AiWriterSidebar/steps/SeoAnalysisStep';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getInspirationsCurrentSubStep } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const performanceTabs = {
  flashScore: 'flashScore',
  plagiarismChecker: 'plagiarismChecker',
  seoMain: 'seoMain',
  seoStatistics: 'seoStatistics',
  seoSearchResults: 'seoSearchResults'
} as const;

export type PerformanceTab = typeof performanceTabs[keyof typeof performanceTabs];

export const PerformanceStep = () => {
  const subStep = useAppSelector(getInspirationsCurrentSubStep);

  switch (subStep) {
    case 'flashScore':
      return <PerformanceContent performanceTab={subStep} />;
    case 'seoMain':
    case 'seoSearchResults':
    case 'seoStatistics':
      return <PerformanceContent performanceTab={subStep} />;
    case 'plagiarismChecker':
      return <PerformanceContent performanceTab={subStep} />;
    default:
      return <PerformanceContent performanceTab="flashScore" />;
  }
};

type Props = {
  performanceTab: PerformanceTab;
};

function PerformanceContent(props: Props) {
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const isSeoAnalysisAvailable = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');
  const isPlagiarismCheckerAvailable =
    useAppSelector(getGetIsFeatureAvailable)('plagiarismChecker');
  const isFlashScoreAvailable = useAppSelector(getGetIsFeatureAvailable)('flashScore');

  const [tab, setTab] = useState(props.performanceTab);

  const subStep = useAppSelector(getInspirationsCurrentSubStep);

  const dispatch = useAppDispatch();

  const translate = useTr();

  const handleTabChange = (_: React.SyntheticEvent, newValue: PerformanceTab) => {
    setTab(newValue);
    dispatch(
      setInspirationsCurrentStep({ step: AiWriterSidebarStep.performance, subStep: newValue })
    );
  };

  const customButton = () => {
    switch (subStep) {
      case 'flashScore':
        return (
          <>
            <FlexContainer style={{ marginRight: 4 }}>
              <FlashScoreFetchingIcon />
            </FlexContainer>
            <ExpandSidebarButton
              isExpanded={isSidebarExpanded}
              onExpand={() => setIsSidebarExpanded(true)}
              onShrink={() => setIsSidebarExpanded(false)}
            />
          </>
        );
      case 'seoMain':
      case 'seoSearchResults':
      case 'seoStatistics':
      case 'plagiarismChecker':
        return (
          <ExpandSidebarButton
            isExpanded={isSidebarExpanded}
            onExpand={() => setIsSidebarExpanded(true)}
            onShrink={() => setIsSidebarExpanded(false)}
          />
        );
    }
  };

  return (
    <AiWriterSidebarPanel
      renderTitle={() => (
        <FlexContainer direction="row" alignItems="center" gap="small">
          <Title title="aiWriter.inspirations.performance.title" />
        </FlexContainer>
      )}
      customButton={customButton()}
    >
      <RootLayout>
        <TabContext value={tab}>
          <StyledTabs
            $isSidebarExtended={isSidebarExpanded}
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.openTab)}
              value={performanceTabs.flashScore}
              label={translate('aiWriter.inspirations.flashScore.title')}
              icon={!isFlashScoreAvailable ? <YellowCrownImage /> : undefined}
              iconPosition="end"
            />
            <Tab
              {...withGtmInteraction(gtmIds.aiWriter.seo.openTab)}
              value={performanceTabs.seoMain}
              label={translate('aiWriter.inspirations.seoAnalysis.title')}
              icon={!isSeoAnalysisAvailable ? <YellowCrownImage /> : undefined}
              iconPosition="end"
            />
            <Tab
              {...withGtmInteraction(gtmIds.aiWriter.plagiarism.openTab)}
              value={performanceTabs.plagiarismChecker}
              label={translate('aiWriter.inspirations.plagiarism.title')}
              icon={!isPlagiarismCheckerAvailable ? <YellowCrownImage /> : undefined}
              iconPosition="end"
            />
          </StyledTabs>
          <StyledTabPanel value={performanceTabs.flashScore}>
            <FlashScoreAnalyzeView />
          </StyledTabPanel>
          <StyledTabPanel value={performanceTabs.seoMain}>
            <SeoAnalysisStep />
          </StyledTabPanel>
          <StyledTabPanel value={performanceTabs.plagiarismChecker}>
            <PlagiarismCheckStep />
          </StyledTabPanel>
        </TabContext>
      </RootLayout>
    </AiWriterSidebarPanel>
  );
}

const StyledTabs = styled(Tabs)<{ $isSidebarExtended: boolean }>`
  min-height: ${({ $isSidebarExtended }) => ($isSidebarExtended ? '35px' : '65px')};
`;

const RootLayout = styled(FlexContainer).attrs({ gap: 'four' })`
  height: 100%;
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 0;
  overflow-y: auto;
`;

import { RouteName } from 'config/routes';
import { AiImagesPage } from 'features/aiImages/AiImagesPage';
import { AiImagesPreviewPage } from 'features/aiImages/AiImagesPreviewPage/AiImagesPreviewPage';
import { NewDocumentPage } from 'features/aiWriter/NewDocumentPage';
import { DesignTemplatesPage } from 'features/design-templates/DesignTemplatesPage';
import { OnboardingBrandVoiceCreation } from 'features/onboardingJourney/OnboardingBrandVoiceCreation';
import { OnboardingBrandVoiceFinished } from 'features/onboardingJourney/OnboardingBrandVoiceFinished';
import { OnboardingSurvey } from 'features/onboardingJourney/OnboardingSurvey';
import { OnboardingUsecase } from 'features/onboardingJourney/OnboardingUsecase';
import { OnboardingWelcome } from 'features/onboardingJourney/OnboardingWelcome';
import { UsersCard } from 'features/profilePage/UsersCard';
import { VerifyAccount } from 'features/VerifyAccount/VerifyAccount';
import { BlogPostBuilderPage } from 'features/workflowBlogPostBuilder/BlogPostBuilderPage';
import { SocialPostBuilderPage } from 'features/workflowSocialPostBuilder/SocialPostBuilderPage';
import ActivationMailPage from 'pages/activationMail';
import AiWriterPage from 'pages/aiWriter';
import { AiWriterPreviewPage } from 'pages/aiWriterPreview';
import { BonusReferralPage } from 'pages/bonusReferral';
import { BrandHubOverviewPage } from 'pages/brand-hub/BrandHubOverviewPage';
import { ModulareWorkflowCreatePage } from 'pages/brand-hub/modular-workflow/create';
import { ModulareWorkflowEditPage } from 'pages/brand-hub/modular-workflow/edit';
import { ModulareWorkflowRunPage } from 'pages/brand-hub/modular-workflow/run';
import { BrandVoicePage } from 'pages/brand-hub-v2/BrandHubV2Page';
import { KnowledgePage } from 'pages/brand-hub-v2/KnowledgePage';
import { WorkflowsPage } from 'pages/brand-hub-v2/WorkflowsPage';
import { CampaignPage } from 'pages/CampaignPage';
import DimensionsManagementPage from 'pages/dimensionsManagement';
import { ExplorerPage } from 'pages/exploring/ExplorerPage';
import { ForgotPasswordPage } from 'pages/forgotPassword/ForgotPasswordPage';
import { FreeRegistrationPage } from 'pages/free/FreeRegistrationPage';
import { HomePage } from 'pages/home/HomePage';
import { LoginCallbackPage } from 'pages/login/LoginCallbackPage';
import { LoginPage } from 'pages/login/LoginPage';
import { MyContentPage } from 'pages/myContent/MyContentPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import PaymentCallbackPage from 'pages/paymentCallback';
import PaymentSuccessPage from 'pages/paymentSuccess';
import PerformanceFlashInfoPage from 'pages/performanceFlashInfo';
import ProfilePage from 'pages/profile';
import { RegisterSuccessPage } from 'pages/registerSuccess/RegisterSuccessPage';
import ResearchFlashInfoPage from 'pages/researchFlashInfo';
import { ResendMail } from 'pages/resendMail/ResendMail';
import { ResetPassword } from 'pages/resetPassword/ResetPassword';
import { ResetPasswordInvalid } from 'pages/resetPassword/ResetPasswordInvalid';
import { SocialLoginCallbackPage } from 'pages/social-login/SocialLoginCallbackPage';
import { PaidSocialRegisterPage } from 'pages/socialRegister/PaidSocialRegisterPage';
import SubjectLinesPage from 'pages/subjectLines';
import { TeamInvitationExistingCustomerLandingpage } from 'pages/teamInvitationExistingCustomerLandingpage';
import { TeamInvitationNewCustomerLandingpage } from 'pages/teamInvitationNewCustomerLandingpage';
import AiTesterPage from 'pages/testing';
import TrialEnforcedLandingPage from 'pages/trialEnforced/TrialEnforcedLandingPage';
import { TrialEnforcedRegistrationPage } from 'pages/trialEnforced/TrialEnforcedRegistrationPage';
import React from 'react';

export const routerRoutes: Record<RouteName, React.ComponentType<unknown>> = {
  activationMail: ActivationMailPage,
  aiWriter: AiWriterPage,
  aiWriterPreview: AiWriterPreviewPage,
  aiImagesPreview: AiImagesPreviewPage,
  aiTester: AiTesterPage,
  dimensionsManagement: DimensionsManagementPage,
  exploring: ExplorerPage,
  forgotPassword: ForgotPasswordPage,
  free: FreeRegistrationPage,
  home: HomePage,
  trialEnforcedLandingPage: TrialEnforcedLandingPage,
  login: LoginPage,
  loginCallback: LoginCallbackPage,
  profileInfo: ProfilePage,
  profileDashboard: ProfilePage,
  profileBilling: ProfilePage,
  workspace: UsersCard,
  register: TrialEnforcedRegistrationPage,
  registerSuccess: RegisterSuccessPage,
  resendMail: ResendMail,
  resetPassword: ResetPassword,
  resetPasswordInvalid: ResetPasswordInvalid,
  subjectLines: SubjectLinesPage,
  socialLoginCallback: SocialLoginCallbackPage,
  aiImages: AiImagesPage,
  paymentSuccess: PaymentSuccessPage,
  paymentCallback: PaymentCallbackPage,
  bonusReferral: BonusReferralPage,
  verifyAccount: VerifyAccount,
  teamInvitationNewCustomerLandingpage: TeamInvitationNewCustomerLandingpage,
  teamInvitationExistingCustomerLandingpage: TeamInvitationExistingCustomerLandingpage,
  onboardingWelcome: OnboardingWelcome,
  onboardingSurvey: OnboardingSurvey,
  onboardingUsecase: OnboardingUsecase,
  onboardingBrandVoiceCreation: OnboardingBrandVoiceCreation,
  onboardingBrandVoiceFinished: OnboardingBrandVoiceFinished,
  socialRegister: PaidSocialRegisterPage,
  productInfoTesting: PerformanceFlashInfoPage,
  productInfoExploring: ResearchFlashInfoPage,
  blogPostWorkflow: BlogPostBuilderPage,
  socialPostWorkflow: SocialPostBuilderPage,
  newDocument: NewDocumentPage,
  modularWorkflowCreateNew: ModulareWorkflowCreatePage,
  modularWorkflowEdit: ModulareWorkflowEditPage,
  modularWorkflowRun: ModulareWorkflowRunPage,
  brandHubOverviewWorkflows: BrandHubOverviewPage,
  brandHubOverviewBrandVoice: BrandHubOverviewPage,
  brandHubOverviewInformation: BrandHubOverviewPage,
  brandHubOverviewTemplates: BrandHubOverviewPage,
  campaign: CampaignPage,
  pageNotFound: NotFoundPage,
  myContent: MyContentPage,
  brandHubV2: BrandVoicePage,
  brandHubV2BrandVoice: BrandVoicePage,
  brandHubV2Workflows: WorkflowsPage,
  brandHubV2Knowledge: KnowledgePage,
  designTemplates: DesignTemplatesPage
};

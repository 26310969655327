import { PersonalityOrBrandVoiceHeaderWrapper } from 'components/personality/PersonalityOrBrandVoiceHeaderWrapper';
import { BrandVoiceAutocomplete } from 'features/brand-voice/BrandVoiceAutocomplete';
import { useField } from 'formik';

type Props = {
  name: string;
  currentLanguage: string;
  currentCountry: string;
};

export const BrandVoiceAutocompleteField = ({ name, currentLanguage, currentCountry }: Props) => {
  const [field, , helpers] = useField<string | undefined>(name);

  const handleBrandVoiceSelect = (brandVoiceId: string | undefined) => {
    helpers.setValue(brandVoiceId);
  };

  return (
    <PersonalityOrBrandVoiceHeaderWrapper>
      <BrandVoiceAutocomplete
        targetLanguage={currentLanguage}
        targetCountry={currentCountry}
        initialBrandVoiceId={field.value}
        onBrandVoiceIdChange={handleBrandVoiceSelect}
      />
    </PersonalityOrBrandVoiceHeaderWrapper>
  );
};

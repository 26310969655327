import StepperModal, { ModalStep } from 'components/modals/StepperModal';
import { CloseModal } from 'components/modals/types';
import { GoalConfigStep } from 'features/aiTester/modals/briefConfig/GoalConfigStep';
import useInitialValues from 'features/aiTester/modals/briefConfig/useInitialValues';
import { changeBriefConfigThunk } from 'features/aiTester/store/actions/config/thunks/changeBriefConfigThunk';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { mkBriefFormValidationSchema } from 'features/textGenerator/utils/mkBriefFormValidation';
import { useCallback, useMemo } from 'react';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export const outputTypeField = 'outputType';
export const textField = 'text';
export const keywords2Field = 'keywords2';
export const keywordsField = 'keywords';
export const systemTonalityField = 'systemTonality';
export const userTonalityField = 'userTonality';
export const numberOfSuggestionsField = 'nTextItems';
export const personalityIdField = 'personalityId';
export const brandVoiceIdField = 'brandVoiceId';
export const informationField = 'informationList';

export type BriefConfigValues = {
  [outputTypeField]: string;
  [textField]: string;
  [keywords2Field]: string;
  [keywordsField]: string;
  [numberOfSuggestionsField]: number;
  [systemTonalityField]: SystemTonality[];
  [userTonalityField]: string[];
  [personalityIdField]: number | undefined;
  [brandVoiceIdField]: string | undefined;
  [informationField]: InformationDto[] | undefined;
};

const modelStep: ModalStep[] = [
  {
    title: 'testing.brief_modal.title',
    description: 'testing.brief_modal.description'
  }
];

type CustomModalProps = {
  preselectedValues?: BriefConfigValues;
};

type Props = CustomModalProps & CloseModal;

const BriefConfigModal = ({ preselectedValues, closeModal }: Props) => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  const validationSchema = useMemo(
    () => mkBriefFormValidationSchema({ textField, keywordsField, keywords2Field, tr }),
    [tr]
  );

  const commonInitialValues = useInitialValues();

  const initialValues = preselectedValues || commonInitialValues;

  const onSubmit = useCallback(
    (values: BriefConfigValues) => {
      dispatch(changeBriefConfigThunk(values));
    },
    [dispatch]
  );

  return (
    <StepperModal
      steps={modelStep}
      stepComponent={() => <GoalConfigStep />}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      tracking={{ saveButtonId: gtmIds.tester.editBriefConfig }}
      onCancel={() => {
        closeModal();
      }}
      onSubmit={values => {
        onSubmit(values);
        closeModal();
      }}
    />
  );
};

export default BriefConfigModal;

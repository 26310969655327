import { changeGenerateTextConfig } from 'features/aiWriter/store/actions/config/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { GenerateTextConfig } from 'features/textGenerator/store/types';
import { useAppDispatch } from 'store/hooks';

type UpdateOptions = {
  brandVoiceId: GenerateTextConfig['brandVoiceId'];
  callback?: (brandVoiceId?: string) => void;
};

export function useUpdateDocumentBrandVoice() {
  const dispatch = useAppDispatch();

  return (props: UpdateOptions) => {
    const { brandVoiceId, callback } = props;

    dispatch(
      changeGenerateTextConfig({
        brandVoiceId
      })
    );

    dispatch(updateCurrentProjectInBackgroundThunk());

    callback?.();
  };
}

export const fetchImageAsBase64 = async (imageUrl: string): Promise<string> => {
  if (!imageUrl) {
    throw new Error('Image URL is required');
  }

  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
    }

    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The result is a data URL string (e.g., "data:image/jpeg;base64,...")
        const result = reader.result as string;
        resolve(result);
      };

      reader.onerror = () => {
        reject(new Error('Failed to convert image to base64'));
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw error instanceof Error ? error : new Error(String(error));
  }
};

import { TElement } from '@udecode/plate';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export type FormValues = {
  language: string;
  title: string;
  goal: string;
  keywords: string[];
  systemTonality: SystemTonality[];
  userTonality: string[];
  brandVoiceId?: string;
  information: InformationDto[] | undefined;
  outline: string;
  generatedContent: TElement[];
  elements: Elements[];
  disableNextStep: boolean;
  /**
   * This is used to determine if the user started the blog builder from the onboarding
   */
  startedFromOnboarding: boolean;
};

export type Elements =
  | 'images'
  | 'youtube_video'
  | 'external_links'
  | 'what_people_asked'
  | 'seobility';

export enum BlogBuilderSteps {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,

  GenerateText
}

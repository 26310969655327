import { PushPin } from '@mui/icons-material';
import { Autocomplete, Popper, SxProps, TextField, Theme } from '@mui/material';
import { ReactComponent as SetPinIcon } from 'assets/set-pin-icon.svg';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const mapModelToOption = (model: EmbeddingModel): ModelOption => ({
  value: model.id,
  label: model.label,
  country: model.country,
  language: model.language
});

export type ModelOption = {
  value: string;
  label: string;
  country: string;
  language: string;
};

type Props = {
  embeddingModels: EmbeddingModel[];
  selectedEmbeddingModelId?: string;
  placeholder?: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
  size?: 'small' | 'medium';
  isSaveAsDefaultShown?: boolean;
  customTextFieldStyles?: SxProps<Theme>;
  customAutocompleteStyles?: SxProps<Theme>;
};

export const HomeChatLanguageSelector = ({
  embeddingModels,
  selectedEmbeddingModelId,
  placeholder,
  disabled,
  onSelect,
  size,
  isSaveAsDefaultShown = false,
  customTextFieldStyles,
  customAutocompleteStyles
}: Props) => {
  const translate = useTr();

  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();
  const { data: customerPreferences } = useCustomerPreferences();
  const defaultModelId = embeddingModels.find(
    model =>
      model.language === customerPreferences?.preferredLanguage &&
      model.country === customerPreferences.preferredLanguageCountry
  )?.id;

  const sortedSelectOptions = useMemo(
    () => sortModelsByLanguageAlphabetically(embeddingModels).map(mapModelToOption),
    [embeddingModels]
  );
  const selectedOption = selectedEmbeddingModelId
    ? sortedSelectOptions.find(v => v.value === selectedEmbeddingModelId)
    : undefined;

  const handleSetPreferredLanguage = (value: ModelOption | undefined) => {
    updatePreferences({
      preferredLanguage: value?.language,
      preferredLanguageCountry: value?.country
    });

    localStorage.setItem(LocalStorageKey.AiWriterModel, String(value?.value));
  };

  const handleSelectChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: ModelOption | null
  ) => {
    if (value) {
      onSelect?.(value.value);
    }
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: ModelOption) => {
    const params = {
      ...props,
      selected: !!selectedOption && selectedOption.value === option.value
    };

    return (
      <Option {...params}>
        <FlexContainer alignItems="center" direction="row" gap="small">
          <CountryFlag country={option.country} language={option.language} />
          {option.label}
        </FlexContainer>

        {isSaveAsDefaultShown &&
          (defaultModelId && defaultModelId === option.value ? (
            <StyledPinIconActive fontSize="small" />
          ) : (
            (selectedOption !== option || option.value !== defaultModelId) && (
              <StyledPinIcon
                fontSize="small"
                className="pinIcon"
                onClick={() => handleSetPreferredLanguage(option)}
              />
            )
          ))}
      </Option>
    );
  };

  const [width, setWidth] = useState('auto');
  const autocompleteRef = useRef(null);

  useLayoutEffect(() => {
    /**
     * This effect is used to calculate the width of the autocomplete based on the selected value
     */
    if (!autocompleteRef.current || !selectedOption) {
      return;
    }

    const currentSelectedOption = sortedSelectOptions.find(
      option => option.value === selectedOption.value
    );
    if (!currentSelectedOption) {
      return;
    }

    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.whiteSpace = 'nowrap';
    tempDiv.innerText = currentSelectedOption.label;
    document.body.appendChild(tempDiv);
    setWidth(`${tempDiv.offsetWidth + 40}px`);
    document.body.removeChild(tempDiv);
  }, [selectedOption, sortedSelectOptions]);

  const popperStyles = {
    popper: {
      minWidth: '250px'
    }
  };

  return (
    <StyledAutocomplete
      ref={autocompleteRef}
      id="model"
      disableClearable={true}
      disabled={disabled}
      options={sortedSelectOptions}
      autoHighlight={true}
      getOptionLabel={option => option.label}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={selectedOption}
      onChange={handleSelectChange}
      PopperComponent={props => <Popper {...props} style={popperStyles.popper} />}
      ListboxProps={{
        style: {
          /**
           * Key object here was to increase the dropdown "height" so that 1440x838res
           * has all options without scroll. I choose that value experimentally
           */
          maxHeight: '60vh'
        }
      }}
      renderInput={params => (
        <StyledTextField
          {...params}
          placeholder={placeholder ?? translate('common.language')}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <CountryFlag country={selectedOption?.country} language={selectedOption?.language} />
            )
          }}
          sx={customTextFieldStyles}
        />
      )}
      size={size}
      sx={{ ...customAutocompleteStyles, width }}
    />
  );
};

const StyledTextField = styled(TextField)`
  background-color: transparent;
`;

const Option = styled.span<{ selected: boolean }>`
  display: flex;
  flex-direction: row;

  .pinIcon {
    display: none;
  }

  &:hover {
    .pinIcon {
      display: block;
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    background-color: transparent;
  }
  .MuiAutocomplete-input {
    cursor: pointer;
  }

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0;
  }
` as typeof Autocomplete;

const StyledPinIcon = styled(SetPinIcon)`
  &,
  & > svg {
    color: ${({ theme }) => theme.colors.componentsIconDefault};
  }

  position: absolute;
  right: 1rem;
`;

const StyledPinIconActive = styled(PushPin)`
  &,
  & > svg {
    color: ${({ theme }) => theme.colors.componentsIconActive};
  }

  position: absolute;
  right: 1rem;
`;

import { useModal } from 'components/modals';
import { CustomModalProps } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatResetModal';
import FormattedMessage from 'features/i18n/FormattedMessage';

const useShowChatResetModal = () => {
  const { showModal } = useModal();

  return (props: CustomModalProps) => {
    showModal('CHAT_RESET_WARNING', {
      ...props,
      size: 480
    });
  };
};

type HookOptions = Omit<Partial<CustomModalProps>, 'onAccept'> & {
  onAccept: () => void;
};

export const useWarnAboutChatReset = () => {
  const showChatResetWarningModal = useShowChatResetModal();

  return (props: HookOptions) =>
    showChatResetWarningModal({
      headingMessage: <FormattedMessage id="chat.reset_chat_warning.heading" />,
      explanationMessage: <FormattedMessage id="chat.reset_chat_warning.message" />,
      ...props
    });
};

import { PersonalityOrBrandVoiceHeaderWrapper } from 'components/personality/PersonalityOrBrandVoiceHeaderWrapper';
import { useChatCurrentGptModel } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useResetChat } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetChat';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import { useUpdateDocumentBrandVoice } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateDocumentBrandVoice';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { BrandVoiceAutocomplete } from 'features/brand-voice/BrandVoiceAutocomplete';
import { useCallback } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

type Props = {
  currentLanguage: string;
  currentCountry: string;
};

export const ProjectBrandVoiceAutocomplete = ({ currentLanguage, currentCountry }: Props) => {
  const tab = useAppSelector(getActiveTab);

  const updateDocumentBrandVoiceId = useUpdateDocumentBrandVoice();
  const resetChat = useResetChat();
  const warnAboutChatReset = useWarnAboutChatReset();

  const gptModel = useChatCurrentGptModel();

  const selectedBrandVoiceId = tab.generateTextConfig.brandVoiceId;
  const selectedPersonalityId = tab.generateTextConfig.personalityId;

  const handleBrandVoiceChange = useCallback(
    (brandVoiceId?: string) => {
      if (brandVoiceId === selectedBrandVoiceId) {
        return;
      }

      return new Promise<boolean>(resolve => {
        warnAboutChatReset({
          onAccept: () => {
            resetChat({
              personalityId: selectedPersonalityId ?? null,
              brandVoiceId: brandVoiceId ?? null,
              gptModel,
              callback: () => {
                updateDocumentBrandVoiceId({ brandVoiceId });
                resolve(true);
              }
            });
          },
          onCancel: () => resolve(false)
        });
      });
    },
    [
      gptModel,
      resetChat,
      selectedBrandVoiceId,
      selectedPersonalityId,
      updateDocumentBrandVoiceId,
      warnAboutChatReset
    ]
  );

  return (
    <PersonalityOrBrandVoiceHeaderWrapper>
      <BrandVoiceAutocomplete
        targetLanguage={currentLanguage}
        targetCountry={currentCountry}
        initialBrandVoiceId={selectedBrandVoiceId ?? undefined}
        onBrandVoiceIdChange={handleBrandVoiceChange}
        onClick={() => GAEvents.overviewPersonalitySelectOpen()}
      />
    </PersonalityOrBrandVoiceHeaderWrapper>
  );
};

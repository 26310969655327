import { blogPostWorkflowOutputTypes } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/stepOutputTypes';
import { initializeBlogPostBuilderThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeBlogPostBuilderThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import { useGetAllDefaultBrandVoices } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getGetEmbeddingModelById } from 'features/embeddingModels/store/selectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useCreateDocument = () => {
  const getWordEmbeddingModel = useSelector(getGetEmbeddingModelById);
  const getAudiences = useAppSelector(getGetAudienceByLanguageAndCountry);
  const dispatch = useAppDispatch();

  const { refetch: fetchBrandVoice } = useGetAllDefaultBrandVoices();

  const createDocument = useCallback(
    async (embeddingModelId: string) => {
      const wordEmbeddingModel = getWordEmbeddingModel(embeddingModelId);
      assertNonNullable(wordEmbeddingModel, 'Model not found');

      const audiences = getAudiences(wordEmbeddingModel.language, wordEmbeddingModel.country);
      const audienceId = audiences[0].id;

      // Note: We HAVE to check this here additionally even if the useQuery in useGetAllDefaultBrandVoices()
      //       checks this in enabled-property, because refetch() doesn't check it
      const brandVoiceResult = await fetchBrandVoice();
      const defaultBrandVoice = brandVoiceResult?.data?.find(
        voice =>
          voice.language === wordEmbeddingModel.language &&
          voice.country === wordEmbeddingModel.country
      );

      dispatch(
        initializeBlogPostBuilderThunk(
          {
            audienceId,
            outputType: blogPostWorkflowOutputTypes.title,
            embeddingModelId,
            name: unnamed,
            isNewDocument: true,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            brandVoiceId: defaultBrandVoice?.brandVoiceId
          },
          { shouldCollapseSidebar: false }
        )
      );
    },
    [getWordEmbeddingModel, getAudiences, fetchBrandVoice, dispatch]
  );

  return { createDocument };
};

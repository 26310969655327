import { BrandVoiceAutocomplete } from 'features/brand-voice/BrandVoiceAutocomplete';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';

export const DataTypeBrandVoice = () => {
  const { input, onChange, step, inputValues } = useContext(InputRendererContext);
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);

  const handleBrandVoiceChange = (brandVoiceId: string | undefined) => {
    onChange(input, brandVoiceId ?? null);
  };

  const languageInput = step.inputs.find(
    inputSetting => inputSetting.data_type.type === 'language'
  );

  let targetLanguage: string | undefined;
  let targetCountry: string | undefined;

  const languageReference = languageInput?.reference;
  if (languageReference) {
    const initLanguageInputValue =
      typeof languageInput.value == 'string' ? languageInput.value : '';
    const langaugeModelId = inputValues[languageReference] ?? initLanguageInputValue;
    const languageModel = getModelById(langaugeModelId as string);

    targetLanguage = languageModel?.language;
    targetCountry = languageModel?.country;
  }

  return (
    <BaseSetting>
      {elementId => (
        <BrandVoiceAutocomplete
          id={elementId}
          fullWidth
          onBrandVoiceIdChange={handleBrandVoiceChange}
          targetLanguage={targetLanguage}
          targetCountry={targetCountry}
        />
      )}
    </BaseSetting>
  );
};

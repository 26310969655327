import { BriefConfigValues } from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import { useShowBriefConfigModal } from 'features/aiTester/modals/briefConfig/useShowBriefConfigModal';
import { ApplyInformationList } from 'features/information/apply-information/ApplyInformationList';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { useField, useFormikContext } from 'formik';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';

type Props = {
  name: string;
};

export function InformationField(props: Props) {
  const [field, , helper] = useField<InformationDto[] | undefined>(props.name);
  const { values } = useFormikContext<BriefConfigValues>();
  const showBriefModal = useShowBriefConfigModal();

  const showApplyInformationModal = useShowApplyInformationModal();

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: field.value,
      applyGtmId: gtmIds.tester.applyInformation,
      onApply: informationList => {
        showBriefModal({
          preselectedValues: {
            ...values,
            informationList
          }
        });
      }
    });
  };

  const handleRemove = (id: number) => {
    helper.setValue(field.value?.filter(information => information.id !== id));
  };

  return (
    <ApplyInformationList
      gtmId={gtmIds.tester.openInformationModal}
      informationList={field.value}
      onApply={handleApplyInformation}
      onRemove={handleRemove}
    />
  );
}

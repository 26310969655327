import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import { PaginatedListParams } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { brandVoicesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/brandVoicesQueryKeys';
import { queryClient } from 'utils/ReactQueryClient';

export type NfpagePage = {
  currentPage?: number;
  size?: number;
  totalElements?: number;
  totalPages?: number;
};

export type BrandvoiceBrandVoicePaginationResponse = {
  data?: Array<ModelsBrandVoice>;
  page?: NfpagePage;
};

export type GetBrandVoicesSuccessDto = BrandvoiceBrandVoicePaginationResponse;

export type GetBrandVoicesParams = PaginatedListParams & {
  workspaceId: string;
  body: {
    /**
     * Filter by brand voice name
     */
    name?: string;
    /**
     * Filter by brand voice name, brand name and URL
     */
    search?: string;
    /**
     * Filter by country (ISO Alpha-2 code)
     */
    country?: string;
    /**
     * Filter by language (ISO Alpha-2 code)
     */
    language?: string;
    /**
     * Filter by user ID
     */
    createdByUserId?: string;
    /**
     * Filter by imported personality ids
     */
    importedFromChatPersonalityIds?: string;
    /**
     * Include related data
     */
    withRelations: boolean;
    /**
     * Include default brand voice first
     */
    includeDefaultFirst?: boolean;
    /**
     * Sort: empty, most_recent, updated_at
     */
    sort?: string;
    /**
     * Page
     */
    page: number;
    /**
     * Page size (1-100)
     */
    size: number;
  };
};

export const httpGetBrandVoicesList = queryEndpoint({
  makeQueryKey: (params: GetBrandVoicesParams) => {
    return [...brandVoicesQueryKeys.forList(), params] as const;
  },
  callEndpoint: ({ workspaceId, body }: GetBrandVoicesParams) => {
    return brandVoiceServiceClient
      .get<GetBrandVoicesSuccessDto>(`workspaces/${workspaceId}/brand-voices`, {
        params: body
      })
      .then(response => response);
  }
});

export function invalidateBrandVoicesQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...brandVoicesQueryKeys.forList()]
  });
}

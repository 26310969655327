import AddIcon from '@mui/icons-material/Add';
import { ListItemIcon, ListItemText } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';

export const CreateBrandVoiceMenuItemContent = () => (
  <>
    <ListItemIcon>
      <AddIcon />
    </ListItemIcon>
    <ListItemText>
      <FormattedMessage id="brand_voice.select.create_new" />
    </ListItemText>
  </>
);

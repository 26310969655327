import { Edit, Favorite, FavoriteBorder, KeyboardReturnRounded } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as ContinueWritingIcon } from 'assets/icons/icon-continue-writing.svg';
import { ReactComponent as ImproveIcon } from 'assets/icons/icon-improve.svg';
import { ReactComponent as RewriteIcon } from 'assets/icons/icon-refresh-magic-feather.svg';
import { ReactComponent as SummarizeIcon } from 'assets/icons/icon-summarize.svg';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { Message } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { ChangeEvent, KeyboardEvent, SyntheticEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { httpFavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpFavoriteTemplate';
import { httpUnfavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpUnfavoriteTemplate';
import {
  SortingTypes,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';

const DEBOUNCE_DELAY = 300;

export const FlashActionsMenu = (props: {
  message: Message;
  anchorEl: HTMLElement | null;
  isOptionsOpen: boolean;
  onCloseMenu: () => void;
  onContinue: (m: Message) => void;
  onRewrite: (m: Message) => void;
  onImprove: (m: Message) => void;
  onSummarize: (m: Message) => void;
  onCustomAction: (command: string, m: Message) => void;
}) => {
  const {
    message,
    onContinue,
    onRewrite,
    onImprove,
    onSummarize,
    anchorEl,
    isOptionsOpen,
    onCloseMenu,
    onCustomAction
  } = props;

  const tr = useTr();
  const { showModal } = useModal();
  const customerId = useAppSelector(state => state.customer.id);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, DEBOUNCE_DELAY);
  function onSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
  }

  const { data: categories } = useTemplateCategoriesQuery();
  const flashActionsId = categories?.filter(
    category => category.alias === flashActionsCategoryAlias
  )[0].id;
  const flashActions = useTemplatesQuery({
    category: flashActionsId,
    title: debouncedSearchQuery.trim().length > 0 ? debouncedSearchQuery : undefined,
    sorting: SortingTypes.FAVORITES
  });
  const refetch = () => {
    flashActions.refetch();
  };

  const { mutate: favoriteTemplate, isLoading: isFavoriteLoading } = useMutation({
    mutationFn: httpFavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });
  const { mutate: unfavoriteTemplate, isLoading: isUnfavoriteLoading } = useMutation({
    mutationFn: httpUnfavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });
  const isFavoriteActionLoading = isFavoriteLoading || isUnfavoriteLoading;

  function handleCheckboxChange(checked: number, template: TemplateDto) {
    if (checked) {
      unfavoriteTemplate({ templateId: template.id });
      return;
    }

    favoriteTemplate({ templateId: template.id });
  }

  const handleContinue = () => {
    onContinue(message);
    onCloseMenu();
  };

  const handleRewrite = () => {
    onRewrite(message);
    onCloseMenu();
  };

  const handleImprove = () => {
    onImprove(message);
    onCloseMenu();
  };

  const handleSummarize = () => {
    onSummarize(message);
    onCloseMenu();
  };

  const handleCustomAction = (e: SyntheticEvent, command: string) => {
    e.stopPropagation();
    onCustomAction(command, message);
    handleCloseMenu();
  };

  const handleCreateNewAction = () => {
    handleCloseMenu();
    showModal('FLASH_ACTIONS', {
      modalMode: 'create',
      categoryId: flashActionsId,
      size: 600
    });
  };

  const handleEditAction = (template: TemplateDto) => {
    handleCloseMenu();
    showModal('FLASH_ACTIONS', {
      modalMode: 'edit',
      categoryId: flashActionsId,
      template,
      size: 600
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter' && debouncedSearchQuery !== '' && !flashActions.isFetching) {
      e.preventDefault();
      handleExecuteSearchQuery(e);
    }
  };

  const renderItems = () => {
    if (!flashActions.data) {
      return null;
    }
    const favoritesList = flashActions.data.pages.map(page => page.data).flat(1);

    if (favoritesList.length === 0 && debouncedSearchQuery !== '') {
      return (
        <ExecuteHint>
          <Typography variant="body2">
            <FormattedMessage id="aiWriter.flash_actions.search.no_results.label" />
          </Typography>
        </ExecuteHint>
      );
    }

    return favoritesList?.map(action => (
      <MenuItem
        {...withGtmInteraction(gtmIds.aiWriter.chat.customFlashAction)}
        key={Math.random()}
        onClick={e => handleCustomAction(e, action.template)}
        disabled={flashActions.isFetching || isFavoriteActionLoading}
      >
        <ListItemIcon
          onClick={e => {
            e.stopPropagation();
            handleCheckboxChange(action.is_liked, action);
          }}
        >
          {action.is_liked ? (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.unfavorite" />}>
              <Favorite fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.favorite" />}>
              <FavoriteBorder fontSize="small" />
            </Tooltip>
          )}
        </ListItemIcon>
        <FlexContainer
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <FlashTitle>{action.title}</FlashTitle>
          {customerId === action.author_id && (
            <StyledIconButton
              onClick={e => {
                e.stopPropagation();
                handleEditAction(action);
              }}
            >
              <Tooltip title={<FormattedMessage id="common.edit" />}>
                <Edit fontSize="small" color="action" />
              </Tooltip>
            </StyledIconButton>
          )}
        </FlexContainer>
      </MenuItem>
    ));
  };

  function renderRetry(messageId: string) {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            flashActions.refetch();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  }

  function renderQueryControls() {
    if (flashActions.isFetching && flashActions.data) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (flashActions.isLoadingError) {
      return renderRetry('aiWriter.inspirations.templates.errors.load');
    }

    if (flashActions.isRefetchError && !flashActions.hasNextPage) {
      return renderRetry('aiWriter.inspirations.templates.errors.refetch');
    }

    if (flashActions.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            flashActions.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  const handleCloseMenu = () => {
    setSearchQuery('');
    onCloseMenu();
  };

  const handleExecuteSearchQuery = (e: SyntheticEvent) => {
    handleCloseMenu();
    handleCustomAction(e, searchQuery);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOptionsOpen}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        style: {
          width: 280,
          maxHeight: 300
        }
      }}
    >
      <SearchField
        variant="standard"
        fullWidth
        placeholder={tr('aiWriter.flash_actions.search_input.label')}
        value={searchQuery}
        onChange={onSearchChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              {debouncedSearchQuery !== '' ? (
                <Tooltip
                  title={
                    <FormattedMessage id="aiWriter.flash_actions.search_input.execute.tooltip" />
                  }
                >
                  <IconButton
                    {...withGtmInteraction(gtmIds.aiWriter.chat.executeSearchQuery)}
                    onClick={handleExecuteSearchQuery}
                  >
                    <KeyboardReturnRounded />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={<FormattedMessage id="common.new" />}>
                  <IconButton
                    {...withGtmInteraction(gtmIds.aiWriter.chat.createFlashAction)}
                    onClick={handleCreateNewAction}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          )
        }}
      />
      <Divider />
      {searchQuery === '' && [
        <MenuItem
          key="continue"
          {...withGtmInteraction(gtmIds.aiWriter.chat.continueFlashAction)}
          onClick={handleContinue}
        >
          <ListItemIcon>
            <ContinueWritingIcon />
          </ListItemIcon>
          <FormattedMessage id="common.continue" />
        </MenuItem>,
        <MenuItem
          key="rewrite"
          {...withGtmInteraction(gtmIds.aiWriter.chat.rewriteFlashAction)}
          onClick={handleRewrite}
        >
          <ListItemIcon>
            <RewriteIcon />
          </ListItemIcon>
          <FormattedMessage id="common.rewrite" />
        </MenuItem>,
        <MenuItem
          key="improve"
          {...withGtmInteraction(gtmIds.aiWriter.chat.improveFlashAction)}
          onClick={handleImprove}
        >
          <ListItemIcon>
            <ImproveIcon />
          </ListItemIcon>
          <FormattedMessage id="common.improve" />
        </MenuItem>,
        <MenuItem
          key="summarize"
          {...withGtmInteraction(gtmIds.aiWriter.chat.summarizeFlashAction)}
          onClick={handleSummarize}
        >
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <FormattedMessage id="common.summarize" />
        </MenuItem>
      ]}
      {flashActions.isLoading && (
        <FlexContainer alignItems="center">
          <CircularProgress size={24} />
        </FlexContainer>
      )}
      {renderItems()}
      {renderQueryControls()}
    </Menu>
  );
};

export const SearchField = styled(TextField)`
  padding: 2px 6px 2px 16px;
`;

export const FlashTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const ExecuteHint = styled.div`
  padding: ${({ theme }) => theme.spacings.small};
`;

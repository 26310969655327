import { logInfo } from 'features/logging/logInfo';

export const createUuidV4 = () => {
  if (typeof window.crypto.randomUUID === 'function') {
    return window.crypto.randomUUID();
  }

  // Improved polyfill implementation based on https://github.com/ungap/random-uuid/blob/main/index.js
  // Using a more robust approach to generate random values

  // Create a buffer of 16 random bytes (128 bits) for the entire UUID
  const randomBytes = window.crypto.getRandomValues(new Uint8Array(16));

  // Set the version bits (bits 12-15 of time_hi_and_version) to 0100 (UUID v4)
  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40;

  // Set the variant bits (bits 6-7 of clock_seq_hi_and_reserved) to 10
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80;

  // Convert to hex values and format as standard UUID
  const uuid = Array.from(randomBytes)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')
    .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');

  logInfo(`created uuid with fallback: ${uuid}`);

  return uuid;
};

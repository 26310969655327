import { Button, ButtonGroup, Card, CircularProgress } from '@mui/material';
import { rgba } from 'polished';
import { MouseEvent, useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';

type FilledCanvasSchema = Parameters<PlacidCanvasInstance['fillLayers']>[0];

type Props = {
  accessToken: string;
  templateUuid: string;
  prefilledCanvasSchema?: FilledCanvasSchema;

  //can be set to different values whenever the template changed to trigger a reload of the template
  templateIterationId: string;

  isLoading?: boolean;
  onEditClick?: (event: MouseEvent<HTMLElement>) => void;
  onDownloadClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const PlacidCanvasCard = ({
  accessToken,
  templateUuid,
  prefilledCanvasSchema,
  isLoading,
  onEditClick,
  onDownloadClick,
  templateIterationId
}: Props) => {
  const elementId = useId();
  const elementRef = useRef<HTMLDivElement>(null);
  const canvasInstanceRef = useRef<PlacidCanvasInstance | null>(null);

  //true once this template was edited at least once. Afterwards we do not prefill
  //the layers anymore since it's baked into the template after the first edit
  const [edited, setEdited] = useState<boolean>(false);

  const handleEditClick = (evt: MouseEvent<HTMLElement>) => {
    setEdited(true);
    if (onEditClick) {
      onEditClick(evt);
    }
  };

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const canvasInstance = window.EditorSDK?.canvas.create(elementRef.current, {
      access_token: accessToken,
      template_uuid: templateUuid
    });
    if (canvasInstance) {
      canvasInstanceRef.current = canvasInstance;

      if (prefilledCanvasSchema && !edited) {
        canvasInstance.fillBySchema(prefilledCanvasSchema);
      }
    }

    const instance = canvasInstanceRef.current;
    return () => {
      instance?.destroy();
      canvasInstanceRef.current = null;
    };
  }, [accessToken, prefilledCanvasSchema, templateUuid, templateIterationId, edited]);

  return (
    <Root elevation={0}>
      <PlacidCanvasContainer id={elementId} ref={elementRef} />

      <OverlayingContainer $isLoading={isLoading}>
        {isLoading ? (
          <CenteredCircularProgress size={36} />
        ) : (
          <ActionItemsContainer>
            <ButtonGroup variant="contained" disabled={isLoading}>
              <Button onClick={handleEditClick}>Edit</Button>
              <Button variant="contained" onClick={onDownloadClick}>
                Download
              </Button>
            </ButtonGroup>
          </ActionItemsContainer>
        )}
      </OverlayingContainer>
    </Root>
  );
};

const Root = styled(Card)`
  position: relative;
  overflow: hidden;
`;

const PlacidCanvasContainer = styled.div``;

const OverlayingContainer = styled.div<{ $isLoading?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 100%;
  width: 100%;
  height: 100%;
  opacity: ${({ $isLoading }) => ($isLoading ? 1 : 0)};
  display: grid;

  transition: opacity 0.3s, background-color 0.1s;

  background-color: ${({ theme, $isLoading }) =>
    $isLoading ? rgba(theme.colors.palette.white, 0.5) : undefined};

  :hover {
    opacity: 1;
  }
`;

const ActionItemsContainer = styled.div`
  display: grid;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const CenteredCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;

  // Because of the animation, the origin isn't centered
  // Hack using negative margin equal to half the size
  margin-top: -18px;
  margin-left: -18px;
`;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from '@mui/material';
import backgroundImage from 'assets/home/background-banner-brand-voice-2.png';
import {
  BANNER_BREAKING_POINT,
  BANNER_HIDE_BREAKING_POINT
} from 'features/homePage/HomeBannerLayout';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const BrandVoiceBanner = () => {
  const translate = useTr();

  const handleClick = () => {
    window.open(translate('brand_hub.brand_voice.article.intro'), '_blank');
  };

  return (
    <Root onClick={handleClick} {...withGtmInteraction(gtmIds.home.brandVoiceBanner)}>
      <ImageContainer>
        <Image src={backgroundImage} alt="background" />
      </ImageContainer>
      <Body>
        <HeaderText>
          <FormattedMessage
            id="home.banner.brandVoice_2_0.title"
            values={{
              highlight: (chunks: string) => <Highlight>{chunks}</Highlight>,
              br: <br />
            }}
          />
        </HeaderText>
      </Body>

      <StyledButton variant="contained" size="small" endIcon={<ArrowForwardIcon />}>
        <FormattedMessage id="home.banner.brandVoice_2_0.button" />
      </StyledButton>
    </Root>
  );
};

const Highlight = styled.span`
  ${({ theme }) => ({
    ...theme.typography.h3
  })}
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 25px;
  right: 20px;

  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  color: ${({ theme }) => theme.colors.commonBlackMain};

  &:hover {
    background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const Root = styled.div`
  position: relative;

  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 200px;
  cursor: pointer;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

const Body = styled.div`
  padding-left: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.two};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h4',
  color: 'white'
})`
  font-style: normal;
  font-weight: 400;
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

import { routesConfig } from 'config/routes';
import { useRef } from 'react';
import { useLocation } from 'react-router';
import { getRouteMatchPath } from 'utils/getRouteMatchPath';

/**
 * Returns the current route path. It includes the route path and the route params.
 * Example:
 * - /aiWriter
 * - /profile/billing
 * - /flash-hub/modular-workflow/run/:id
 */
export const useCurrentRoutePath = () => {
  const { pathname } = useLocation();

  const routesRef = useRef(Object.values(routesConfig));
  const currentRouteMatch = getRouteMatchPath(routesRef.current, pathname);

  return currentRouteMatch || pathname;
};

import { Typography } from '@mui/material';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import { BaseField } from 'features/modular-workflow/runner/fields/BaseField';
import { FieldRendererContext } from 'features/modular-workflow/runner/fields/FieldRenderer';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const maxInputLength = 15000;

export const TextInputField = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { input, onChange, onValidationChange, inputErrorList, onKeyDown } =
    useContext(FieldRendererContext);

  const [localValue, setLocalValue] = useState<string>((input.value as string) || '');

  const isLengthExceeded = localValue.length > maxInputLength;

  const isValid = useMemo(() => {
    if (input.is_required && localValue.length === 0) {
      return false;
    }

    if (isLengthExceeded) {
      return false;
    }

    return true;
  }, [input.is_required, localValue.length, isLengthExceeded]);

  const hasError = inputErrorList.length > 0 || isLengthExceeded;

  useEffect(() => {
    onValidationChange(input.reference, isValid);
    // Disable ESlint check because we only wanna react on the isValid change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const handleTextChange = (newValue: string) => {
    if (newValue.length > maxInputLength) {
      // Still update local value to show the correct count in the UI
      setLocalValue(newValue);
      return;
    }

    setLocalValue(newValue);
    onChange(input, newValue);
  };

  return (
    <BaseField>
      {elementId => (
        <>
          <StyledTextareaAutosize
            onKeyDown={onKeyDown}
            ref={textAreaRef}
            id={elementId}
            defaultValue={input.value as string}
            placeholder={input.description}
            // Matches roughly 400px
            maxRows={15}
            onChange={event => {
              handleTextChange(event.target.value);
            }}
            onPaste={() => {
              // Need to use setTimeout because the paste content isn't immediately available
              setTimeout(() => {
                if (textAreaRef.current) {
                  handleTextChange(textAreaRef.current.value);
                }
              }, 0);
            }}
            $hasError={hasError}
          />
          <CounterBox>
            {inputErrorList.length > 0 ? (
              <div>
                {inputErrorList.map(({ errorMessage }) => (
                  <Typography key={Math.random()} variant="caption" color="error">
                    {errorMessage}
                  </Typography>
                ))}
              </div>
            ) : (
              <div />
            )}
            <Typography variant="caption" color={hasError ? 'error' : undefined}>
              {localValue.length}/{maxInputLength}
            </Typography>
          </CounterBox>
        </>
      )}
    </BaseField>
  );
};

const CounterBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

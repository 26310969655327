import AuthRoute from 'components/auth/AuthRoute';
import { routerRoutes } from 'config/routerRoutes';
import {
  getRoutePath,
  getRouteUrl,
  RouteName,
  routesConfig,
  shouldPageBeVisible
} from 'config/routes';
import {
  getCustomerRole,
  getCustomerTeams,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import { LoginPage } from 'pages/login/LoginPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { useMemo } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export const useGetRoutes = () => {
  const customerGroupId = useAppSelector(state => state.customer.groupId);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);

  const redirects = useMemo(
    () => [
      { from: '/bubble-graph', to: getRoutePath('exploring') },
      { from: '/concept-flash', to: getRoutePath('exploring') },
      { from: '/content-flash', to: getRoutePath('aiTester') },
      {
        from: '/brand-hub/overview/information',
        to: getRouteUrl('brandHubV2Knowledge')
      },
      {
        from: '/brand-hub/overview/brand-voice',
        to: getRouteUrl('brandHubV2BrandVoice')
      },
      {
        from: '/brand-hub/overview/workflows',
        to: getRouteUrl('brandHubV2Workflows')
      },
      {
        from: '/brand-hub/overview/templates',
        // No dedicated URL for this page
        to: getRouteUrl('brandHubV2')
      }
    ],
    []
  );

  return useMemo(
    () =>
      [<Route path="/" key="/" element={<LoginPage />} />]
        .concat(
          Object.entries(routesConfig)
            .filter(([, config]) =>
              shouldPageBeVisible(config, customerGroupId, userRole, isApiCustomer, customerTeams)
            )
            // Remove old routes from old brand hub to make redirects working
            .filter(([, config]) => {
              if (
                [
                  routesConfig.brandHubOverviewWorkflows.path,
                  // TODO: Disabled to allow creating old personalities and test legacy feature
                  //       Will be removed on PROD
                  //routesConfig.brandHubOverviewBrandVoice.path,
                  routesConfig.brandHubOverviewInformation.path,
                  routesConfig.brandHubOverviewTemplates.path
                ].includes(config.path)
              ) {
                return false;
              }

              return true;
            })
            .map(([route, config]) => {
              const RouteComponent = routerRoutes[route as RouteName];
              const routeElement = config.auth ? (
                <AuthRoute component={RouteComponent} routeName={route as RouteName} />
              ) : (
                <RouteComponent />
              );

              return <Route path={config.path} key={config.path} element={routeElement} />;
            })
        )
        .concat(
          redirects
            .map(({ from, to }) => (
              <Route key={`${from}_${to}`} path={from} element={<Navigate to={to} />} />
            ))
            .concat(<Route key="*" path="*" element={<NotFoundPage />} />)
        ),
    [redirects, customerGroupId, userRole, isApiCustomer, customerTeams]
  );
};

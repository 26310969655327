import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { BrandVoiceAutocomplete } from 'features/brand-voice/BrandVoiceAutocomplete';
import { useGetDefaultBrandVoice } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { ApplyInformationList } from 'features/information/apply-information/ApplyInformationList';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { FormValues } from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import { SocialPostBuilderStepLayout } from 'features/workflowSocialPostBuilder/SocialPostBuilderStepLayout';
import { SocialBuilder } from 'features/workflowSocialPostBuilder/WorkflowSchema';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const Step2 = () => {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const translate = useTr();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const model = getModelById(values.embeddingModelId);
  // It's ensured in the layout that this step won't be rendered if the model doesn't support tonalities
  const languageAndCountryCode =
    `${model.language}_${model.country}` as AiWriterSupportedLanguageAndCountryCode;

  const defaultPersonalityOrBrandVoiceParams = {
    language: model.language,
    country: model.country
  };

  const { data: defaultBrandVoice } = useGetDefaultBrandVoice(defaultPersonalityOrBrandVoiceParams);

  useEffect(() => {
    if (defaultBrandVoice) {
      setFieldValue('brandVoiceId', defaultBrandVoice.brandVoiceId);
    }
  }, [defaultBrandVoice, setFieldValue]);

  const showApplyInformationModal = useShowApplyInformationModal();

  const handleBrandVoiceChange = (brandVoiceId: string | undefined) => {
    setFieldValue('brandVoiceId', brandVoiceId);
  };

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: values.informationList,
      onApply: informationList => {
        setFieldValue('informationList', informationList);
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    setFieldValue(
      'informationList',
      values.informationList?.filter(information => information.id !== id)
    );
  };

  return (
    <SocialPostBuilderStepLayout>
      <Typography variant="h6">
        <FormattedMessage id="social_post_builder.step1.title" />
      </Typography>

      <TonalitySelect
        label={translate('social_post_builder.tonality_step.label')}
        systemTonality={values.systemTonality}
        userTonality={values.userTonality}
        maxLength={2}
        languageAndCountryCode={languageAndCountryCode}
        onChange={(newSystemTonalities, newUserTonalities) => {
          setFieldValue('systemTonality', newSystemTonalities);
          setFieldValue('userTonality', newUserTonalities);
        }}
        onInputChange={inputValue => {
          // Disable nex step if user entered a tonality but didn't confirmed it
          setFieldValue('disableNextStep', inputValue.length > 0);
        }}
        gtmAttributes={withGtmInteraction(gtmIds.socialPostBuilder.tonality)}
      />

      <FullWidthContainer>
        <SocialBuilder.Label
          labelId="common.brandVoice"
          tooltipId="social_post_builder.tonality_step.brandVoice.tooltip"
          linkId="aiWriter.inspirations.aiwriter.main.personality.help_link"
        />
        <BrandVoiceAutocomplete
          targetLanguage={model.language}
          targetCountry={model.country}
          initialBrandVoiceId={values.brandVoiceId}
          onBrandVoiceIdChange={handleBrandVoiceChange}
          size="small"
          fullWidth={true}
        />
      </FullWidthContainer>

      <ApplyInformationList
        informationList={values.informationList}
        onApply={handleApplyInformation}
        onRemove={handleInformationRemove}
        fontVariant="subtitle1"
      />
    </SocialPostBuilderStepLayout>
  );
};

const FullWidthContainer = styled(FlexContainer).attrs({ gap: 'one' })`
  width: 100%;
`;

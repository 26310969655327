import { useGetBrandVoicesInfinite } from 'features/brand-voice/useGetBrandVoicesInfinite';
import { getUserId } from 'features/customer/store/selectors';
import { useAppSelector } from 'store/hooks';

export const useGetBrandVoiceCountCreatedByCustomer = () => {
  const customerId = useAppSelector(getUserId);

  const queryOptions = {
    page: 1,
    size: 1,
    withRelations: false,
    createdByUserId: String(customerId)
  };

  const { totalItems } = useGetBrandVoicesInfinite(queryOptions);

  return totalItems;
};

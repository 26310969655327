import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BrandVoiceBanner } from 'features/homePage/BrandVoiceBanner';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import styled from 'styled-components';

export const HomeBanner = () => {
  const { isLoading } = useQuery({
    queryKey: httpGetPersonalities.makeQueryKey({}),
    queryFn: () => httpGetPersonalities.callEndpoint({})
  });

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return <BrandVoiceBanner />;
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { saveBrandVoiceId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import {
  ChatOptimizePromptButton,
  IMPROVE_PROMPT_OUTPUT_TYPE
} from 'features/aiWriter/chat/ChatOptimizePromptButton';
import { GptSelect } from 'features/aiWriter/chat/GptSelect';
import { TemplateSelect } from 'features/aiWriter/chat/TemplateSelect';
import { useIsGpt4Disabled } from 'features/aiWriter/chat/useIsGpt4Disabled';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { ExtendedChatInputBrandVoiceSelect } from 'features/brand-voice/ExtendedChatInputBrandVoiceSelect';
import { MessageConfig } from 'features/homePage/chatForm/CreateChatForm';
import { InformationButton } from 'features/information/apply-information/InformationButton';
import { usePromptOptimizerControlled } from 'features/textGenerator/promptOptimizer/usePromptOptimizerContorlled';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

type Props = {
  modelId: string;
  isSending: boolean;
  value: MessageConfig;
  sendButtonConfig: { gtmId: string; disabled?: boolean };

  onChange: (message: MessageConfig) => void;
  onSend: (text: string) => void;
};

export const HomeMessageInput = ({ modelId, isSending, value, onChange, onSend }: Props) => {
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const ignoreBlurRef = useRef(false);
  const translate = useTr();

  const postHog = usePostHog();

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const isGpt4Disabled = useIsGpt4Disabled();

  const { text, gptModel, brandVoiceId } = value;

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(modelId);

  const {
    isUndoVisible,
    setInitialPrompt,
    optimizePromptMutation: { mutate: optimizePrompt, isLoading: isOptimizationLoading },
    undoPromptOptimization,
    setIsUndoVisible
  } = usePromptOptimizerControlled({
    prompt: text,
    setPrompt: (prompt: string) => onChange({ ...value, text: prompt }),
    locale: { language, country },
    initialPrompt: '',
    outputType: IMPROVE_PROMPT_OUTPUT_TYPE
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.shiftKey || e.key !== 'Enter') {
      return;
    }

    e.preventDefault();

    GAEvents.sentChatMessage({ source: 'enter' });
    postHog?.capture('CF - chat message sent via overview');
    onSend(text);
  };

  const handleGptModelChange = (event: SelectChangeEvent<unknown>) => {
    inputRef.current?.focus();
    const newModel = event.target.value as GptModel;
    // if upgrade option is selected it has no value therefore we do nothing here
    if (!newModel) {
      return;
    }

    if (isGpt4Disabled) {
      showUpgradeSubscriptionModal();
      return;
    }

    onChange({ ...value, gptModel: newModel });
  };

  const handleTemplateSelect = (template: string) => {
    onChange({ ...value, text: template });

    onSend(template);

    inputRef.current?.focus();

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const handleBrandVoiceChange = (brandVoiceId: string | null) => {
    onChange({ ...value, brandVoiceId });

    saveBrandVoiceId(brandVoiceId);

    inputRef.current?.focus();

    // Note: The default brand voice is updated inside of the select automatically
  };

  const handleInformationChange = (informationList: InformationDto[]) => {
    onChange({ ...value, informationList });
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...value, text: e.currentTarget.value });

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const handleOptimizeClick = () => {
    inputRef.current?.focus();

    setInitialPrompt(text);

    optimizePrompt();
  };

  const handleFocus = () => {
    setIsEditorFocused(true);
  };

  const handlePersonalitySelectBlurBecauseOfClose = () => {
    // this is required only for the GPT select because for some reason onBlur is triggered before onMouseDown
    // therefore all the icons got hidden before we could even click on them
    setTimeout(() => {
      if (!ignoreBlurRef.current) {
        setIsEditorFocused(false);
      }

      ignoreBlurRef.current = false;
    }, 0);
  };

  const handlePersonalitySelectIconMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    ignoreBlurRef.current = true;
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }} {...withTestId('chatflash-element-box')}>
      <StyledTextField
        value={text}
        onChange={handleTextFieldChange}
        onKeyDown={handleKeyDown}
        aria-label={translate('chat.message_input.aria_label')}
        placeholder={translate('chat.message_input.placeholder')}
        disabled={isSending}
        multiline={true}
        minRows={4}
        maxRows={4}
        ref={inputRef}
        fullWidth
        onFocus={handleFocus}
        onBlur={handlePersonalitySelectBlurBecauseOfClose}
        {...withTestId('chatflash-text-input-field')}
      />
      {isEditorFocused ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <ActionBox>
            <ExtendedChatInputBrandVoiceSelect
              selectedBrandVoiceId={brandVoiceId ?? undefined}
              targetLanguage={language}
              targetCountry={country}
              menuOpeningDirection="bottom"
              onBrandVoiceChange={handleBrandVoiceChange}
              onMouseDown={handlePersonalitySelectIconMouseDown}
              onClose={handlePersonalitySelectBlurBecauseOfClose}
            />

            <InformationButton
              onMouseDown={handlePersonalitySelectIconMouseDown}
              onInformationChange={handleInformationChange}
            />

            <GptSelect
              onClick={() => GAEvents.overviewGptSelectOpen()}
              value={gptModel}
              onChange={handleGptModelChange}
              menuOpeningDirection="bottom"
              onBlur={handlePersonalitySelectBlurBecauseOfClose}
              onMouseDown={handlePersonalitySelectIconMouseDown}
              isGpt4Disabled={isGpt4Disabled}
            />

            <TemplateSelect
              modelId={modelId}
              onSelection={handleTemplateSelect}
              onMouseDown={handlePersonalitySelectIconMouseDown}
            />
          </ActionBox>

          <ChatOptimizePromptButton
            onMouseDown={handlePersonalitySelectIconMouseDown}
            prompt={text}
            undoVisible={isUndoVisible}
            loading={isOptimizationLoading}
            onOptimizeClick={handleOptimizeClick}
            onUndoClick={undoPromptOptimization}
          />
        </Box>
      ) : null}
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.backgroundPaperElevation2 : theme.colors.commonWhiteMain};

  .MuiInputBase-root {
    border-radius: ${({ theme }) => theme.borderRadius.one};

    textarea {
      margin-bottom: 22px;
    }
  }
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

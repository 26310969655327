import { Container } from 'components/Container';
import { AiImagesGeneratorContextProvider } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import {
  DesignTemplatesForm,
  GenerateDesignTemplatesOptions
} from 'features/design-templates/form/DesignTemplatesForm';
import { TemplateGrid } from 'features/design-templates/TemplateGrid';
import { TemplateHeader } from 'features/design-templates/TemplateHeader';
import { useTemplateCreation } from 'features/design-templates/useTemplateCreation';
import { useTemplateOperations } from 'features/design-templates/useTemplateOperations';
import { useTemplateTextGeneration } from 'features/design-templates/useTemplateTextGeneration';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import styled from 'styled-components';

export const DesignTemplatesPage = () => {
  useEnableSidebarOnMount();
  useNavigateToOnboarding();

  const isDesignTemplatesEnabled = useFeatureFlagEnabled('placid-closed-beta');

  const {
    templates,
    setTemplates,
    createDesignTemplatesOptions,
    isFetchingTemplates,
    handleGenerateDesignTemplates
  } = useTemplateCreation();

  const {
    handleEditTemplateClick,
    handleDownloadTemplateClick,
    lastClickedTemplateIndex,
    isGeneratingEditToken,
    isGeneratingImage
  } = useTemplateOperations(templates, setTemplates, createDesignTemplatesOptions);

  const { resetTextGeneration } = useTemplateTextGeneration(
    templates,
    setTemplates,
    createDesignTemplatesOptions
  );

  const onGenerateDesignTemplates = async (options: GenerateDesignTemplatesOptions) => {
    const result = await handleGenerateDesignTemplates(options);
    if (!result) {
      return;
    }

    resetTextGeneration();
  };

  if (!isDesignTemplatesEnabled) {
    return (
      <Body>
        <FormattedMessage id="designTemplates.disabled" />
      </Body>
    );
  }

  return (
    <AiImagesGeneratorContextProvider>
      <TemplateHeader />

      <Body>
        <DesignTemplatesForm
          onGenerateClick={onGenerateDesignTemplates}
          isGenerateDisabled={isFetchingTemplates}
        />

        <TemplateGrid
          templates={templates}
          isFetchingTemplates={isFetchingTemplates}
          templateCount={createDesignTemplatesOptions?.amount || 6}
          lastClickedTemplateIndex={lastClickedTemplateIndex}
          isGeneratingImage={isGeneratingImage}
          isGeneratingEditToken={isGeneratingEditToken}
          onEditClick={handleEditTemplateClick}
          onDownloadClick={handleDownloadTemplateClick}
        />
      </Body>
    </AiImagesGeneratorContextProvider>
  );
};

const Body = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};
`;

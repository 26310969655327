import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import styled from 'styled-components';

type Props = {
  isLoading: boolean;
  selectedBrandVoice?: ModelsBrandVoice;
  onClick: () => void;
};

export const BrandVoiceSelectAdornment = ({ isLoading, onClick, selectedBrandVoice }: Props) => {
  if (selectedBrandVoice) {
    return null;
  }

  if (isLoading) {
    return (
      <InputAdornment position="start">
        <ProgressBox>
          <CircularProgress size={16} />
        </ProgressBox>
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="start" onClick={onClick}>
      <PersonalityIcon />
    </InputAdornment>
  );
};

const ProgressBox = styled(Box)`
  display: grid;
`;

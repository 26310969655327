import CheckroomIcon from '@mui/icons-material/Checkroom';
import { Box, FormHelperText, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getLocalFileBase64 } from 'utils/getLocalFileBase64';
import useTr from 'utils/hooks/useTr';

const avatarSize = 100;

type Props = {
  image?: string;
  setImage: (image: string) => void;
};

export const EditableImage = ({ image, setImage }: Props) => {
  const translate = useTr();

  const [error, setError] = useState<string | undefined>(undefined);

  const handleImageUpload = async () => {
    setError(undefined);

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg, image/jpg, image/svg+xml';
    input.onchange = async e => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) {
        return;
      }

      // Validate file type
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
      if (!validTypes.includes(file.type)) {
        setError(translate('brand_hub.brand_voice.profile.overview_card.invalid_image_format'));
        return;
      }

      const result = await getLocalFileBase64(file);
      setImage(result);
    };

    input.click();
  };

  return (
    <div>
      <UploadableImageCircle onClick={handleImageUpload} src={image} $hasError={!!error}>
        {!image && <StyledCheckroomIcon />}

        <HiddenTypography className="update-label" variant="caption">
          <FormattedMessage id="brand_hub.brand_voice.profile.overview_card.update_label" />
        </HiddenTypography>
      </UploadableImageCircle>
      {error && <StyledFormHelperText error>{error}</StyledFormHelperText>}
    </div>
  );
};

interface UploadableImageCircleProps {
  src?: string;
  $hasError?: boolean;
}

const UploadableImageCircle = styled(Box)<UploadableImageCircleProps>`
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: ${({ theme, src }) => (src ? 'transparent' : theme.colors.commonWhiteMain)};
  background-image: ${props => (props.src ? `url(${props.src})` : 'none')};
  background-size: cover;
  background-position: center;
  border: ${({ theme, $hasError }) =>
    $hasError
      ? `2px solid ${theme.colors.errorMain}`
      : `2px solid ${theme.colors.commonBlackOutlinedborder}`};
  transition: all 0.3s ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryColorHover};

    .update-label {
      opacity: 1;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const HiddenTypography = styled(Typography)`
  position: absolute;
  color: ${({ theme }) => theme.colors.commonWhiteMain};

  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
`;

const StyledCheckroomIcon = styled(CheckroomIcon)`
  width: 60px;
  height: 60px;
  color: ${({ theme }) => theme.colors.commonBlackOutlinedborder};
`;

const StyledFormHelperText = styled(FormHelperText)`
  word-wrap: break-word;
  overflow-wrap: break-word;

  max-width: 100px;
`;

import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { Suggestion } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateSuggestions';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export type ActiveConversationDto = {
  conversation_id: string;
  personality_id: number;
  brand_voice_id: string;
  current_model: GptModel;
  suggestions: Suggestion[];
  informations: InformationDto[];
} | null;

type SuccessDto = BaseSuccessDto<ActiveConversationDto>;

export const httpGetActiveConversation = queryEndpoint({
  makeQueryKey: (props: { projectId: string }) =>
    [
      makeCurrentUserQueryKeyPrefix(),
      'ai-writer',
      'projects',
      'item',
      props.projectId,
      'active-conversation'
    ] as const,
  callEndpoint: (props: { projectId: string }) => {
    const { projectId } = props;
    return backofficeClient
      .get<SuccessDto>(`/projects/ai-writer/${projectId}/active-conversation`)
      .then(response => response.data.data);
  }
});

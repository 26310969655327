import { Badge } from '@mui/material';
import { ReactComponent as NotificationsIcon } from 'assets/sidebar/icon-notifications.svg';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNotificationCenterEntries } from 'features/notifications/useNotificationCenterEntries';
import { SidebarItem, SidebarItemText } from 'features/pageSidebar/sidebarItems/SidebarItem';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export const SidebarItemNotificationCenter = (): React.ReactElement => {
  const { showModal } = useModal();

  const notifications = useNotificationCenterEntries();
  const numberOfNotifications = notifications.length;

  const notificationIcon =
    numberOfNotifications > 0 ? (
      <SmallerBadge badgeContent={numberOfNotifications} color="error">
        <NotificationsIcon />
      </SmallerBadge>
    ) : (
      <NotificationsIcon />
    );

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.notifications}
      onClick={() => showModal('NOTIFICATIONS', { size: 675 })}
      icon={notificationIcon}
      tooltip="layout.sidebar.actions.notifications"
    >
      <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
        <SidebarItemText variant="body1">
          <FormattedMessage id="layout.sidebar.actions.notifications" />
        </SidebarItemText>
      </FlexContainer>
    </SidebarItem>
  );
};

const SmallerBadge = styled(Badge)`
  & .MuiBadge-badge {
    // Usually, this is 0; but due the tiny size, we've to move it a bit further towards
    // the icons to make it look good.
    top: 2px;
    right: 2px;

    font-size: 60%;
    line-height: 0.6;
    height: auto;
    min-height: 14px;
    min-width: 14px;
    padding: 0 4px;
    transform-origin: center;
  }
`;

import { useMutation } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { configureSocialPostBuilderThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureSocialPostBuilderThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { useGetDefaultOutputTypeByLanguageMemoized } from 'features/textGenerator/utils/useGetPromptsOthers';
import { FormValues } from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export function useCreateDocument() {
  const { values } = useFormikContext<FormValues>();
  const embeddingModelId = values.embeddingModelId;
  const dispatch = useAppDispatch();
  const getModelLanguageCountryById = useAppSelector(getGetEmbeddingModelLanguageAndCountryById);
  const { language: modelLanguage, country: modelCountry } =
    getModelLanguageCountryById(embeddingModelId);
  const defaultOutputType = useGetDefaultOutputTypeByLanguageMemoized(modelLanguage, modelCountry);
  const getModelAudiences = useGetModelAudiences();

  return useMutation({
    mutationFn: async () => {
      const audiences = getModelAudiences(embeddingModelId);
      const audienceId = getAudienceInitValue(audiences, LocalStorageKey.AiWriterProject) ?? 0;

      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId,
            audienceId,
            name: unnamed,
            isNewDocument: true,
            outputType: defaultOutputType,
            brief: values.outline,
            keywords: '',
            keywords2: '',
            tonality: [...values.systemTonality, ...values.userTonality],
            personalityId: values.personalityId?.id,
            brandVoiceId: values.brandVoiceId
          },
          configureSocialPostBuilderThunk
        )
      );
    }
  });
}

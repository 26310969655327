import { createSelector } from '@reduxjs/toolkit';
import {
  useChatCurrentGptModel,
  useIsChatEmpty
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useResetConversationMutation } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetConversationMutation';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import { useUpdateDocumentBrandVoice } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateDocumentBrandVoice';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { ExtendedChatInputBrandVoiceSelect } from 'features/brand-voice/ExtendedChatInputBrandVoiceSelect';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';

const selectCurrentBrandVoiceId = createSelector(
  [getActiveTab],
  activeTab => activeTab.generateTextConfig.brandVoiceId
);

/**
 * Wrapper around {@link ExtendedChatInputBrandVoiceSelect} to handle chat reset logic
 * on brand voice change.
 */
export const SidebarExtendedChatBrandVoiceSelect = () => {
  const activeTab = useAppSelector(getActiveTab);
  const embeddingModel = useAppSelector(getEmbeddingModelDataSelector)(activeTab.embeddingModelId);
  const { language, country } = embeddingModel;
  const currentBrandVoiceId = useAppSelector(selectCurrentBrandVoiceId);

  const updateDocumentBrandVoice = useUpdateDocumentBrandVoice();
  const { mutate: resetConversation } = useResetConversationMutation();
  const warnAboutChatReset = useWarnAboutChatReset();
  const isChatEmpty = useIsChatEmpty();

  const gptModel = useChatCurrentGptModel();

  const handleBrandVoiceChange = (brandVoiceId: string | null) => {
    if (isChatEmpty) {
      resetConversation({ brandVoiceId, gptModel: gptModel ?? null });

      updateDocumentBrandVoice({ brandVoiceId });
      return true;
    }

    return new Promise<boolean>(resolve => {
      warnAboutChatReset({
        onAccept: () => {
          resetConversation({
            brandVoiceId,
            gptModel: gptModel ?? null
          });

          updateDocumentBrandVoice({ brandVoiceId });
          resolve(true);
        },
        onCancel: () => resolve(false)
      });
    });
  };

  return (
    <ExtendedChatInputBrandVoiceSelect
      selectedBrandVoiceId={currentBrandVoiceId ?? undefined}
      targetLanguage={language}
      targetCountry={country}
      menuOpeningDirection="bottom"
      onBrandVoiceChange={handleBrandVoiceChange}
    />
  );
};

import { Button, LinearProgress, Link, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal, ModalProps } from 'components/modals/types';
import { SearchField } from 'components/SearchField';
import { PersonalityTiles } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityTiles';
import { useDeleteChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDeleteChatPersonality';
import { useUpdateChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateChatPersonality';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  personalityCreationModalHeight,
  PersonalityModalProps,
  useShowPersonalityCreationModal
} from 'features/personality/creation/PersonalityCreationModal';
import {
  MaxHeightOverflowContainer,
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ComponentProps, useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';
import { infiniteQueryKey } from 'utils/reactQuery/infiniteQueryKey';
import { forceNonNullable } from 'utils/typescript/nonNullable';

function usePersonalitiesQuery(params: { language: string; country: string; search?: string }) {
  return useInfiniteQuery({
    queryKey: infiniteQueryKey(httpGetPersonalities.makeQueryKey(params)),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetPersonalities.callEndpoint({ ...params, ...pageParam });
    },
    getNextPageParam: getNextPageParam
  });
}

export type PersonalitySelectHandler = (personality: PersonalityDto | null) => void;

type CustomModalProps = PersonalityModalProps & {
  languageModelId: string;
  onPersonalitySelect: PersonalitySelectHandler;
};

type Props = CustomModalProps & CloseModal;

export const PersonalityLibraryModal = ({
  languageModelId,
  onPersonalitySelect,
  closeModal,
  ...rest
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const { language, country } = getModelById(languageModelId);

  const personalitiesResult = usePersonalitiesQuery({
    language,
    country,
    search: debouncedSearchQuery
  });
  const { mutateAsync: deletePersonality } = useDeleteChatPersonality();
  const { mutateAsync: updatePersonality } = useUpdateChatPersonality();

  // TODO: If you ever reuse this code, please check for default brtand voice instead
  // const storedPersonalityId = useStoredPersonalityId();
  // const defaultPersonalityResult = useDefaultPersonalityQuery({ language, country });
  // const activePersonalityId = storedPersonalityId || defaultPersonalityResult.data?.id || null;
  const storedPersonalityId: number | null = null;
  const activePersonalityId = storedPersonalityId || null;

  const handlePersonalityDeleteClick: ComponentProps<typeof PersonalityTiles>['onDeleteClick'] =
    async personality => {
      await deletePersonality(personality.id);

      if (personality.id === storedPersonalityId) {
        onPersonalitySelect(null);
      }
    };

  const handlePersonalityShareClick = async (
    personality: PersonalityDto,
    isTeamShared: boolean
  ) => {
    await updatePersonality({
      ...personality,
      personalityId: personality.id,
      definition: forceNonNullable(personality.definition),
      sharing_permission: isTeamShared ? 'private' : 'team'
    });
  };

  const showPersonalityCreationModal = useShowPersonalityCreationModal();

  const handleCreatePersonalityClick = () => {
    closeModal();

    showPersonalityCreationModal({
      languageModelId: languageModelId,
      ...rest
    });

    trackingWrapper.track('aiWriterPersonalityCreateClicked');
  };

  const handlePersonalityDetailsClick: ComponentProps<typeof PersonalityTiles>['onDetailsClick'] =
    personality => {
      closeModal();

      showPersonalityCreationModal({
        languageModelId: languageModelId,
        personalityToEdit: personality,
        initialViewMode: 'create_or_edit',
        ...rest
      });
    };

  const handlePersonalitySelect: ComponentProps<typeof PersonalityTiles>['onTileClick'] =
    personality => {
      onPersonalitySelect(personality);

      closeModal();
    };

  const items = personalitiesResult.data?.pages.map(page => page.data).flat(1);

  function renderRetry(messageId: string) {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            personalitiesResult.refetch();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  }

  function renderQueryControls() {
    if (personalitiesResult.isFetching) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (personalitiesResult.isLoadingError) {
      return renderRetry('aiWriter.inspirations.chat.personalities.errors.load');
    }

    if (personalitiesResult.isRefetchError && !personalitiesResult.hasNextPage) {
      return renderRetry('aiWriter.inspirations.chat.personalities.errors.refetch');
    }

    if (personalitiesResult.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            personalitiesResult.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.title" />}
      helpLinkTooltip={<TooltipMessage />}
      closeModal={closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
    >
      <OptionsBox>
        <SearchField value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />

        <CreationButton
          {...withGtmInteraction(gtmIds.personalitiesLibrary.openNewPersonalityCreator)}
          color="primary"
          variant="contained"
          onClick={handleCreatePersonalityClick}
        >
          <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.create_new" />
        </CreationButton>
      </OptionsBox>

      {items && (
        <MaxHeightOverflowContainer>
          <PersonalityTiles
            personalities={items}
            activePersonalityId={activePersonalityId}
            onTileClick={handlePersonalitySelect}
            onDetailsClick={handlePersonalityDetailsClick}
            onDeleteClick={handlePersonalityDeleteClick}
            onShareClick={handlePersonalityShareClick}
          />
        </MaxHeightOverflowContainer>
      )}

      {renderQueryControls()}
    </ModalWithDividedHeaderLayout>
  );
};

function TooltipMessage() {
  const { showModal } = useModal();
  const translate = useTr();

  function handleVideoClick() {
    showModal('YOUTUBE_VIDEO', {
      size: 1100,
      videoId: translate('aiWriter.inspirations.chat.personalities.library_modal.video_link_id')
    });
  }

  return (
    <FormattedMessage
      id="aiWriter.inspirations.chat.personalities.library_modal.tooltip"
      values={{
        br: <br />,
        video: (content: string) => (
          <Link component="button" onClick={handleVideoClick}>
            {content}
          </Link>
        ),
        help: (content: string) => (
          <Link
            href={translate(
              'aiWriter.inspirations.chat.personalities.library_modal.helpcenter_link_id'
            )}
            rel="noreferrer"
            target="_blank"
          >
            {content}
          </Link>
        )
      }}
    />
  );
}

const OptionsBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

const CreationButton = styled(Button)`
  margin-left: auto;
`;

export const useShowPersonalityLibraryModal = () => {
  const { showModal } = useModal();

  return (props: CustomModalProps & ModalProps) => {
    showModal('PERSONALITY_LIBRARY', { ...props, size: 1100 });
  };
};

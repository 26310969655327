import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { brandVoicesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/brandVoicesQueryKeys';

export type httpGetDefaultBrandVoicesParams = {
  workspaceId: string;
  customerId: number;
};

type httpGetDefaultBrandVoicesSuccessDto = BaseSuccessDto<ApiDefaultBrandVoiceDto[]>;

export type ApiDefaultBrandVoiceDto = {
  brandVoiceId: string;
  country: string;
  createdAt?: string;
  /**
   * not set for workspaces imported from legacy team
   */
  createdByUserId?: string;
  id?: string;
  language: string;
  legacyCustomerId?: number;
  updatedAt?: string;
};

export const httpGetDefaultBrandVoices = queryEndpoint({
  makeQueryKey: (params: httpGetDefaultBrandVoicesParams) => {
    return [...brandVoicesQueryKeys.forDefaultBrandVoices(), params] as const;
  },
  callEndpoint: ({ workspaceId, customerId }: httpGetDefaultBrandVoicesParams) => {
    return brandVoiceServiceClient
      .get<httpGetDefaultBrandVoicesSuccessDto>(
        `workspaces/${workspaceId}/customers/${customerId}/default-brand-voices`,
        {
          params: {
            size: 100
          }
        }
      )
      .then(response => response.data.data);
  }
});

import { SearchOutlined } from '@mui/icons-material';
import {
  ClickAwayListener,
  InputAdornment,
  Paper as MuiPaper,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material';
import {
  AutocompleteDropdownMenuContent,
  BrandVoiceOption,
  EMPTY_OPTION
} from 'features/brand-voice/components/AutocompleteDropdownMenuContent';
import { BrandVoiceThumbnailAvatar } from 'features/brand-voice/components/BrandVoiceThumbnailAvatar';
import { TextFieldEndAdornment } from 'features/brand-voice/components/TextFieldEndAdornment';
import { useBrandVoiceAutocompleteData } from 'features/brand-voice/useBrandVoiceAutocompleteData';
import { useRedirectToBrandVoiceCreation } from 'features/brand-voice/useRedirectToBrandVoiceCreation';
import { useSetDefaultBrandVoiceMutation } from 'features/brand-voice/useSetDefaultBrandVoiceMutation';
import { ChangeEvent, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = TextFieldProps & {
  targetLanguage?: string;
  targetCountry?: string;
  initialBrandVoiceId?: string;

  onBrandVoiceIdChange?: (
    brandVoiceId: string | undefined
  ) => void | boolean | PromiseLike<void | boolean>;
};

export const BrandVoiceAutocomplete = ({
  targetLanguage,
  targetCountry,
  initialBrandVoiceId,
  onBrandVoiceIdChange,
  ...textFieldProps
}: Props) => {
  const translate = useTr();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [selectedOption, setSelectedOption] = useState<BrandVoiceOption | null>(null);

  const inputRef = useRef<HTMLDivElement>(null);

  const redirectToBrandVoiceCreation = useRedirectToBrandVoiceCreation();
  const { mutate: setDefaultBrandVoice } = useSetDefaultBrandVoiceMutation();

  const { isLoading, brandVoices, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useBrandVoiceAutocompleteData({
      initialBrandVoiceId,
      targetLanguage,
      targetCountry,
      search: isSearching ? inputValue : undefined
    });

  useEffect(() => {
    // If data has been loaded and we've got an initial value from parent component,
    // update our local state to use it as selected option
    if (initialBrandVoiceId && brandVoices.length > 0 && !selectedOption) {
      const foundBrandVoice = brandVoices.find(brandVoice => brandVoice.id === initialBrandVoiceId);
      if (foundBrandVoice) {
        setSelectedOption(foundBrandVoice);
      }
    }
    // This hook should not react on initialBrandVoiceId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandVoices, selectedOption]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setInputValue(value);
    setIsSearching(true);
  };

  const handleOpen = () => {
    setOpen(true);

    // Select the whole text in the input field
    inputRef.current?.querySelector('input')?.select();
  };

  const handleOptionSelect = async (option: BrandVoiceOption) => {
    const updateLocalState = () => {
      setSelectedOption(option);
      setInputValue('');
      setOpen(false);
      setIsSearching(false);
    };

    try {
      if (option.id === EMPTY_OPTION.id) {
        updateLocalState();

        await onBrandVoiceIdChange?.(undefined);
        return;
      }

      if (onBrandVoiceIdChange) {
        const result = await onBrandVoiceIdChange(option.id || '');
        if (result === false) {
          return;
        }
      }

      updateLocalState();

      if (option.id) {
        setDefaultBrandVoice({ brandVoiceId: option.id });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error selecting brand voice:', error);
    }
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (inputRef.current?.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
    setIsSearching(false);
  };

  const handleCreateClick = (event: ReactMouseEvent) => {
    event.stopPropagation();

    redirectToBrandVoiceCreation();
  };

  return (
    <Root>
      <TextField
        {...textFieldProps}
        ref={inputRef}
        fullWidth={true}
        placeholder={translate('brand_voice.select.selection_placeholder')}
        value={
          isSearching || !selectedOption
            ? inputValue
            : selectedOption.id === EMPTY_OPTION.id
            ? translate(selectedOption.name)
            : selectedOption.name || ''
        }
        onChange={handleInputChange}
        onFocus={handleOpen}
        onClick={handleOpen}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isSearching || !selectedOption ? (
                <SearchOutlined fontSize="small" />
              ) : (
                <BrandVoiceThumbnailAvatar thumbnailUrl={selectedOption?.thumbnailUrl} size={18} />
              )}
            </InputAdornment>
          ),
          endAdornment: <TextFieldEndAdornment isLoading={isLoading} isOpen={open} />,
          autoComplete: 'off'
        }}
      />

      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <DropdownMenuPaper>
            <AutocompleteDropdownMenuContent
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              selectedValue={selectedOption ?? null}
              brandVoices={brandVoices}
              onCreateClick={handleCreateClick}
              onOptionSelect={handleOptionSelect}
              onFetchNextPage={fetchNextPage}
            />
          </DropdownMenuPaper>
        </ClickAwayListener>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const TextField = styled(MuiTextField)`
  width: 100%;
`;

const DropdownMenuPaper = styled(MuiPaper)`
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  margin-top: ${({ theme }) => theme.spacings.two};
  z-index: 1000;
`;

import { getIsAuthenticated } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useEmbeddedLanguageToolWidget } from 'features/languageToolIntegration/useEmbeddedLanguageToolWidget';
import { useLanguageToolTracking } from 'features/languageToolIntegration/useLanguageToolTracking';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

function OnlyWhenAuthenticated() {
  const toggleLanguageTool = useEmbeddedLanguageToolWidget();
  const { data: preferences } = useCustomerPreferences();

  useEffect(() => {
    toggleLanguageTool(preferences?.languageToolEnabled ?? false);
  }, [preferences?.languageToolEnabled, toggleLanguageTool]);

  return null;
}

// TODO: This is a temporary widget to track the language tool usage.
// TODO: Remove this once we collect the data we need.
function TrackingEnabledWidget() {
  const postHog = usePostHog();

  useLanguageToolTracking({
    onPopupOpen: () => {
      postHog?.capture('language_tool_popup_opened');
    }
  });

  return null;
}

export const LanguageToolWidget = () => {
  const isTrackingEnabled = useFeatureFlagEnabled('language-tool-tracking');
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <OnlyWhenAuthenticated />
      {isTrackingEnabled === true && <TrackingEnabledWidget />}
    </>
  );
};

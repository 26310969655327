import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { httpGetBrandVoiceById } from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpGetBrandVoiceById';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Options = {
  brandVoiceId?: string | null;
  enabled?: boolean;
};

export const useGetBrandVoiceById = ({ brandVoiceId, enabled = true }: Options) => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  return useQuery({
    enabled: enabled && !!workspaceId && !!brandVoiceId,
    queryKey: httpGetBrandVoiceById.makeQueryKey({
      workspaceId: workspaceId ?? '',
      brandVoiceId: brandVoiceId ?? ''
    }),
    queryFn: () => {
      assertNonNullable(brandVoiceId, 'currentBrandVoiceId is required');
      assertNonNullable(workspaceId, 'workspaceId is required');

      return httpGetBrandVoiceById.callEndpoint({
        workspaceId,
        brandVoiceId
      });
    },
    // Retry only if the error is not a 404
    retry: (_retryCount, error: AxiosError) => error.status !== 404
  });
};

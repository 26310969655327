import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetBrandVoiceById } from 'features/brand-voice/useGetBrandVoiceById';
import { useAppSelector } from 'store/hooks';

export const useGetDocumentBrandVoiceModel = () => {
  const {
    generateTextConfig: { brandVoiceId }
  } = useAppSelector(getActiveTab);

  return useGetBrandVoiceById({ brandVoiceId });
};

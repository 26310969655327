import { useGetBrandVoiceById } from 'features/brand-voice/useGetBrandVoiceById';
import { useGetBrandVoicesInfiniteSelectOptions } from 'features/brand-voice/useGetBrandVoicesInfiniteSelectOptions';

type Options = {
  initialBrandVoiceId?: string;
  targetLanguage?: string;
  targetCountry?: string;
  search?: string;
};

export const useBrandVoiceAutocompleteData = ({
  initialBrandVoiceId,
  targetLanguage,
  targetCountry,
  search
}: Options) => {
  const {
    brandVoices,
    isLoading: isLoadingBrandVoices,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useGetBrandVoicesInfiniteSelectOptions({
    language: targetLanguage,
    country: targetCountry,
    search
  });

  const { data: currentBrandVoice, isInitialLoading: isInitialLoadingCurrentBrandVoice } =
    useGetBrandVoiceById({
      brandVoiceId: initialBrandVoiceId
    });

  const isLoading =
    isLoadingBrandVoices || (!!initialBrandVoiceId && isInitialLoadingCurrentBrandVoice);

  // Add single brand voice to the start of the list, if it's matching the filter
  if (!isLoadingBrandVoices && brandVoices && currentBrandVoice) {
    if (
      currentBrandVoice.language === targetLanguage &&
      currentBrandVoice.country === targetCountry &&
      !brandVoices.find(brandVoice => brandVoice.id === currentBrandVoice.id)
    ) {
      brandVoices.unshift(currentBrandVoice);
    }
  }

  return {
    isLoading,
    brandVoices,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  };
};

import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import StepperModal, { ModalStep } from 'components/modals/StepperModal';
import { CloseModal } from 'components/modals/types';
import { GoalConfigValues } from 'features/aiTester/modals/goalConfig/GoalConfigModal';
import SetupAudienceSelect from 'features/aiTester/modals/setup/SetupAudienceSelect';
import SetupMapperSelect from 'features/aiTester/modals/setup/SetupMapperSelect';
import SetupModelSelect from 'features/aiTester/modals/setup/SetupModelSelect';
import useHandleOutputTypeChange from 'features/aiTester/modals/setup/useHandleOutputTypeChange';
import useInitialValues from 'features/aiTester/modals/setup/useInitialValues';
import useShowSetupModal from 'features/aiTester/modals/setup/useShowSetupModal';
import { changeGoalConfigThunk } from 'features/aiTester/store/actions/config/thunks/changeGoalConfigThunk';
import { initializeTabThunk } from 'features/aiTester/store/actions/tabs/thunks/initializeTabThunk';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { defaultWordAttributes } from 'features/aiTester/store/utils/defaults/defaultWordAttributes';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { BrandVoiceAutocompleteField } from 'features/brand-voice/BrandVoiceAutocompleteField';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { ApplyInformationList } from 'features/information/apply-information/ApplyInformationList';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import AdvancedSettingsButton from 'features/textGenerator/AdvancedSettingsButton';
import BriefField from 'features/textGenerator/inputs/BriefField';
import KeywordsField from 'features/textGenerator/inputs/KeywordsField';
import OutputTypeAutocomplete from 'features/textGenerator/OutputTypeAutocomplete/OutputTypeAutocomplete';
import { GenerateTextConfig } from 'features/textGenerator/store/types';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getValidationForField } from 'utils/getValidationForField';
import useRunInTask from 'utils/hooks/useRunInTask';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

export const modelField = 'model';
export const mapperField = 'mapper';
export const audienceField = 'audience';
export const outputTypeField = 'outputType';
export const textBodyField = 'textBody';
export const keywords2Field = 'keywords2';
export const keywordsField = 'keywords';
export const systemTonalityField = 'systemTonality';
export const userTonalityField = 'userTonality';
export const numberOfSuggestionsField = 'nTextItems';
export const personalityIdField = 'personalityId';
export const brandVoiceIdField = 'brandVoiceId';
export const informationField = 'information';

export type TesterSetupValues = {
  [modelField]: string;
  [mapperField]: string;
  [audienceField]: number;
  [outputTypeField]: string;
  [textBodyField]: string;
  [keywords2Field]: string;
  [keywordsField]: string;
  [numberOfSuggestionsField]: number;
  [systemTonalityField]: SystemTonality[];
  [userTonalityField]: string[];
  [personalityIdField]: number | undefined;
  [brandVoiceIdField]: string | undefined;
  [informationField]: InformationDto[] | undefined;
};

export const defaultOutputType = 'slogans';

const modalStep: ModalStep[] = [
  {
    title: 'testing.setup.models.title',
    okButton: {
      text: 'common.next'
    },
    cancelButton: {
      hide: false
    }
  }
];

export type SetupModalProps = {
  preselectedValues?: TesterSetupValues;
  isMoreOptionsExpanded?: boolean;
};

const SetupModal = ({
  preselectedValues,
  isMoreOptionsExpanded,
  closeModal
}: SetupModalProps & CloseModal) => {
  const dispatch = useAppDispatch();
  const tr = useTr();
  const { showModal } = useModal();

  const [isLoading, runInTask] = useRunInTask();
  const projectName = tr('common.unnamed');

  const validationSchema = useMemo(
    () =>
      object().shape({
        [modelField]: string().required(tr(getValidationForField('model'))),
        [textBodyField]: string().required(tr(getValidationForField('text')))
      }),
    [tr]
  );

  const commonInitialValues = useInitialValues();

  const initialValues = preselectedValues || commonInitialValues;

  const { wordAttributes, scoreByOpenRate, manualDimensions, dimensions } =
    useAppSelector(getTesterActiveTab);

  const onSubmit = useCallback(
    async (values: TesterSetupValues) => {
      const model = values[modelField];
      const mapper = values[mapperField];
      const audience = values[audienceField];
      const outputType = values[outputTypeField];
      const textBody = values[textBodyField];
      const brand = values[keywords2Field];
      const keywords = values[keywordsField];
      const systemTonality = values[systemTonalityField];
      const userTonality = values[userTonalityField];
      const nTextItems = values[numberOfSuggestionsField];
      const personalityId = values[personalityIdField];
      const brandVoiceId = values[brandVoiceIdField];
      const information = values[informationField];

      const generateTextConfig: GenerateTextConfig = {
        audienceId: audience,
        brand,
        keywords,
        outputType,
        text: textBody,
        systemTonality,
        userTonality,
        nTextItems,
        personalityId,
        brandVoiceId,
        informationList: information
      };

      await runInTask(() =>
        dispatch(
          initializeTabThunk({
            name: projectName,
            embeddingModelId: model,
            mapperId: mapper,
            generateTextConfig,
            wordAttributes: defaultWordAttributes
          })
        )
      );

      closeModal();
      showModal('TESTING_GOAL_MODAL', {
        size: 460,
        initialValues: { wordAttributes, scoreByOpenRate, manualDimensions, dimensions },
        onSubmit: (values: GoalConfigValues) => {
          dispatch(changeGoalConfigThunk(values));
        }
      });
    },
    [
      runInTask,
      closeModal,
      showModal,
      wordAttributes,
      scoreByOpenRate,
      manualDimensions,
      dimensions,
      dispatch,
      projectName
    ]
  );

  return (
    <StepperModal
      validateOnChange={false}
      isLoading={isLoading}
      steps={modalStep}
      stepComponent={() => <MainStep isMoreOptionsExpanded={isMoreOptionsExpanded} />}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onCancel={() => closeModal()}
      onSubmit={onSubmit}
      tracking={{ saveButtonId: gtmIds.tester.startNewProject }}
    />
  );
};

const MainStep = ({
  isMoreOptionsExpanded
}: {
  isMoreOptionsExpanded?: boolean;
}): React.ReactElement => {
  const getModelLanguageAndCountryById = useAppSelector(getGetEmbeddingModelLanguageAndCountryById);

  const {
    values: { model, audience, outputType },
    setFieldValue
  } = useFormikContext<TesterSetupValues>();

  const { language, country } = getModelLanguageAndCountryById(model);

  const onOutputTypeChange = useHandleOutputTypeChange(language, country);

  return (
    <FlexContainer gap="three">
      <SetupModelSelect />
      <SetupMapperSelect />
      <SetupAudienceSelect />
      <OutputTypeAutocomplete
        name={outputTypeField}
        isHidden={audience === undefined}
        modelLanguage={language}
        modelCountry={country}
        onSelect={value => onOutputTypeChange(value, setFieldValue)}
      />
      <BriefField
        name={textBodyField}
        modelLanguage={language}
        modelCountry={country}
        outputType={outputType}
      />
      <KeywordsField
        name={keywords2Field}
        type="keywords2"
        modelLanguage={language}
        modelCountry={country}
        outputType={outputType}
      />
      <KeywordsField
        name={keywordsField}
        type="keywords"
        modelLanguage={language}
        modelCountry={country}
        outputType={outputType}
      />
      <AdvancedSettingsButton isExpanded={isMoreOptionsExpanded}>
        <BrandVoiceAutocompleteField
          name={brandVoiceIdField}
          currentLanguage={language}
          currentCountry={country}
        />

        <Information name={informationField} />
      </AdvancedSettingsButton>
    </FlexContainer>
  );
};

type InformationProps = {
  name: string;
};

function Information(props: InformationProps) {
  const [field, , helper] = useField<InformationDto[] | undefined>(props.name);
  const { values } = useFormikContext<TesterSetupValues>();

  const showApplyInformationModal = useShowApplyInformationModal();
  const showSetupModal = useShowSetupModal();

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: field.value,
      applyGtmId: gtmIds.tester.applyInformation,
      onApply: informationList => {
        showSetupModal({
          preselectedValues: {
            ...values,
            information: informationList
          },
          isMoreOptionsExpanded: true
        });
      }
    });
  };

  const handleRemove = (id: number) => {
    helper.setValue(field.value?.filter(information => information.id !== id));
  };

  return (
    <ApplyInformationList
      gtmId={gtmIds.tester.openInformationModal}
      informationList={field.value}
      onApply={handleApplyInformation}
      onRemove={handleRemove}
    />
  );
}

export default SetupModal;

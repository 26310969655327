import { useMutation } from '@tanstack/react-query';
import { saveCurrentSuggestions } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import {
  CreateSuggesionParams,
  httpCreateSuggestions
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateSuggestions';

export const useCreateSuggestions = () => {
  return useMutation({
    mutationFn: (params: CreateSuggesionParams) => {
      const trimmedKeywords = params.keywords?.trim();
      const trimmedKeywords2 = params.keywords2?.trim();
      const audienceModelId = params.audience_model_id;

      if (!trimmedKeywords || !trimmedKeywords2 || !audienceModelId) {
        return Promise.resolve(null);
      }

      return httpCreateSuggestions.callEndpoint({
        ...params,
        keywords: trimmedKeywords,
        keywords2: trimmedKeywords2
      });
    },
    onSuccess: data => {
      if (!data) return;
      saveCurrentSuggestions(data.suggestions);
      return data.suggestions;
    }
  });
};

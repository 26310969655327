import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

type PlacidEditorModalProps = {
  accessToken: string;
  templateId: string;
  prefilledLayers: LayerConfiguration | undefined;
  onClose?: () => void;
};

type Props = CloseModal & PlacidEditorModalProps;

export const PlacidEditorModal = ({
  closeModal,
  accessToken,
  templateId,
  prefilledLayers,
  onClose
}: Props) => {
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('open editor with ', {
      access_token: accessToken,
      template_uuid: templateId,
      prefill_layers: prefilledLayers
    });

    const editor = window.EditorSDK?.editor.create(editorRef.current, {
      access_token: accessToken,
      template_uuid: templateId,
      prefill_layers_by_schema: prefilledLayers
    });

    editor?.on('editor:closed', () => {
      onClose?.();
      closeModal();
    });
    editor?.on('editor:template:saved', data => {
      // eslint-disable-next-line no-console
      console.log(
        'TODO: update the templateId and the layer data in the corresponding canvas component',
        data
      );
    });

    return () => {
      editor?.destroy();
    };
  }, [accessToken, closeModal, onClose, prefilledLayers, templateId]);

  return (
    <EditorContainer>
      <EditorWrapper ref={editorRef} />
    </EditorContainer>
  );
};

export const useShowPlacidEditorModal = () => {
  const { showModal } = useModal();

  return (props: PlacidEditorModalProps) => {
    showModal('PLACID_EDITOR', {
      ...props,
      size: 'fullscreen'
    });
  };
};

const EditorContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const EditorWrapper = styled.div`
  flex: 1;
`;

import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';

function makeBaseKey() {
  return [...makeCurrentUserQueryKeyPrefix(), 'brandVoices'] as const;
}

export const brandVoicesQueryKeys = {
  forList: () => [...makeBaseKey(), 'list'] as const,
  forItem: () => [...makeBaseKey(), 'item'] as const,
  forLimitations: () => [...makeBaseKey(), 'limitations'] as const,
  forGetDefault: () => [...makeBaseKey(), 'get-default'] as const,
  forDefaultBrandVoices: () => [...makeBaseKey(), 'defaultBrandVoices'] as const,
  forBrandVoiceById: (brandVoiceId: string) =>
    [...makeBaseKey(), 'brandVoiceById', brandVoiceId] as const
};

import Toast from 'components/toasts/Toast';
import { testerProjectFromApi } from 'features/aiTester/store/utils/mappers/testerProjectMappers';
import { testerTabFromProject } from 'features/aiTester/store/utils/mappers/testerTabMappers';
import { wordAttributesConfigFromWordAttributeOptions } from 'features/aiTester/store/utils/mappers/wordAttributesMappers';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import AiTesterAPI from 'services/api/aiTester';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { AppThunk } from 'store/store';

import { insertProject } from '../../project/actions';
import { generateSuggestionsThunk } from '../../suggestions/thunks/generateSuggestionsThunk';
import { initializeTab } from '../actions';
import { InitializeTestingTabPayload } from '../types';

export const initializeTabThunk =
  (payload: InitializeTestingTabPayload): AppThunk<void> =>
  async dispatch => {
    try {
      const response = await AiTesterAPI.create({
        name: payload.name,
        texts: [],
        text_inspirations: [],
        embedding_model_id: payload.embeddingModelId,
        mapper_id: payload.mapperId,
        output_type: payload.generateTextConfig.outputType,
        audience_id: payload.generateTextConfig.audienceId,
        keywords: payload.generateTextConfig.keywords ?? '',
        keywords2: payload.generateTextConfig.brand ?? '',
        brief: payload.generateTextConfig.text ?? '',
        user_tonality: payload.generateTextConfig.userTonality ?? [],
        system_tonality: (payload.generateTextConfig.systemTonality ?? []) as SystemTonality[],
        n_text_items: payload.generateTextConfig.nTextItems ?? 1,
        dimensions: [],
        manual_dimensions: [],
        score_by_open_rate: true,
        word_attribute_config: wordAttributesConfigFromWordAttributeOptions(
          payload.wordAttributes ?? []
        ),
        personality_id: payload.generateTextConfig.personalityId ?? null,
        brand_voice_id: payload.generateTextConfig.brandVoiceId ?? null,
        information_ids: payload.generateTextConfig.informationList?.map(
          information => information.id
        )
      });

      if (response.status) {
        const project = testerProjectFromApi(response.data);
        const tab = testerTabFromProject(project);

        dispatch(initializeTab(tab));
        dispatch(insertProject(project));
        dispatch(generateSuggestionsThunk());
      } else {
        Toast.backendError(handleCommonApiError(response.data.message));
      }
    } catch (e) {
      Toast.commonError();
    }
  };

import { CircularProgress, Divider, MenuItem } from '@mui/material';
import { InfiniteDropdownIntersectionObserver } from 'components/InfiniteDropdownIntersectionObserver';
import { BrandVoiceMenuItemContent } from 'features/brand-voice/components/BrandVoiceMenuItemContent';
import { CreateBrandVoiceMenuItemContent } from 'features/brand-voice/components/CreateBrandVoiceMenuItemContent';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { MouseEvent as ReactMouseEvent } from 'react';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import styled from 'styled-components';

export type BrandVoiceOption = {
  id?: string;
  name: IntlMessageKeys;
  thumbnailUrl?: string;
  brand?: {
    name?: string;
  };
};

export const EMPTY_OPTION: BrandVoiceOption = {
  id: 'empty',
  name: 'brand_voice.select.undefined',
  thumbnailUrl: ''
};

type Props = {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  selectedValue: BrandVoiceOption | null;
  brandVoices: ModelsBrandVoice[];

  onCreateClick: (event: ReactMouseEvent) => void;
  onOptionSelect?: (option: BrandVoiceOption) => void;
  onFetchNextPage: () => void;
};

export const AutocompleteDropdownMenuContent = ({
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  selectedValue,
  brandVoices,
  onCreateClick,
  onOptionSelect,
  onFetchNextPage
}: Props) => {
  if (isLoading) {
    return (
      <LoadingMenuItem disabled={true}>
        <CircularProgress size={24} />
      </LoadingMenuItem>
    );
  }

  return (
    <>
      <MenuItem onClick={onCreateClick}>
        <CreateBrandVoiceMenuItemContent />
      </MenuItem>

      <Divider />

      <MenuItem
        key={EMPTY_OPTION.id}
        value={EMPTY_OPTION.id}
        onClick={onOptionSelect ? () => onOptionSelect(EMPTY_OPTION) : undefined}
        selected={selectedValue?.id === EMPTY_OPTION.id || !selectedValue}
      >
        <FormattedMessage id={EMPTY_OPTION.name} />
      </MenuItem>

      {brandVoices.length > 0 && (
        <>
          {brandVoices.map(option => (
            <MenuItem
              key={option.id || option.name}
              value={option.id}
              onClick={onOptionSelect ? () => onOptionSelect(option) : undefined}
              selected={selectedValue?.id === option.id}
            >
              <BrandVoiceMenuItemContent option={option} />
            </MenuItem>
          ))}

          <InfiniteDropdownIntersectionObserver
            as="div"
            onIsInView={onFetchNextPage}
            isAlreadyFetching={isFetchingNextPage}
            enabled={hasNextPage}
          />
        </>
      )}
    </>
  );
};

const LoadingMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacings.three} 0`};
`;

import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import { brandVoicesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/brandVoicesQueryKeys';

type GetBrandVoiceByIdParams = {
  workspaceId: string;
  brandVoiceId: string;
};

type GetBrandVoiceByIdSuccessDto = ModelsBrandVoice;

export const httpGetBrandVoiceById = queryEndpoint({
  makeQueryKey: (params: GetBrandVoiceByIdParams) => {
    return [...brandVoicesQueryKeys.forBrandVoiceById(params.brandVoiceId)] as const;
  },
  callEndpoint: ({ workspaceId, brandVoiceId }: GetBrandVoiceByIdParams) => {
    return brandVoiceServiceClient
      .get<GetBrandVoiceByIdSuccessDto>(`workspaces/${workspaceId}/brand-voices/${brandVoiceId}`)
      .then(response => response.data);
  }
});

import { Avatar } from '@mui/material';
import styled from 'styled-components';

type Props = {
  thumbnailUrl?: string;
  /**
   * The size in pixel, will be used for width & height
   */
  size?: number;
};

export const BrandVoiceThumbnailAvatar = ({ thumbnailUrl, size = 24 }: Props) => (
  <StyledAvatar src={thumbnailUrl} $hasThumbnail={!!thumbnailUrl} $size={size} />
);

const StyledAvatar = styled(Avatar)<{ $hasThumbnail: boolean; $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border: ${({ $hasThumbnail, theme }) =>
    $hasThumbnail ? `1px solid ${theme.colors.commonBlackFocusvisible}` : 'none'};
`;

import { changeBriefConfig } from 'features/aiTester/store/actions/config/actions';
import { ChangeBriefConfigPayload } from 'features/aiTester/store/actions/config/types';
import { updateTesterProjectInBackgroundThunk } from 'features/aiTester/store/actions/project/thunks/updateTesterProjectInBackgroundThunk';
import { generateSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/generateSuggestionsThunk';
import { AppThunk } from 'store/store';

export const changeBriefConfigThunk =
  (payload: ChangeBriefConfigPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(changeBriefConfig(payload));
    dispatch(generateSuggestionsThunk());
    dispatch(updateTesterProjectInBackgroundThunk());
  };

import { CircularProgress } from '@mui/material';
import type { Location } from '@remix-run/router';
import { useMutation } from '@tanstack/react-query';
import fallbackImage from 'assets/onboarding/fallback_avatar.png';
import { getRoutePath } from 'config/routes';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { useSetDefaultBrandVoiceMutation } from 'features/brand-voice/useSetDefaultBrandVoiceMutation';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { BrandVoiceCard } from 'features/onboardingJourney/components/BrandVoiceCard';
import { OnboardingStepper } from 'features/onboardingJourney/components/OnboardingStepper';
import { OnboardingBrandVoiceTextExtractionLocationState } from 'features/onboardingJourney/new-onboarding-2025/onboarding-creation-new';
import { OnboardingFooter } from 'features/onboardingJourney/OnboardingFooter';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { isObject } from 'lodash';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import {
  CreateBrandVoiceParams,
  httpCreateBrandVoice
} from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import { GAEvents } from 'services/tracking/GAEvents';
import { fetchImageAsBase64 } from 'utils/fetchImageAsBase64';
import { useTimeout } from 'utils/hooks/useTimeout';
import useWindowSize from 'utils/hooks/useWindowSize';
import { assertNonNullable } from 'utils/typescript/nonNullable';

const showConfettiSeconds = 2;

export const OnboardingBrandVoiceFinishedNew = () => {
  const { state: locationState } =
    useLocation() as Location<OnboardingBrandVoiceTextExtractionLocationState>;

  const navigate = useNavigate();

  const [image, setImage] = useState<string | undefined>(undefined);

  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);

  useDisableSidebarOnMount();

  useEffect(() => {
    if (!locationState || !isObject(locationState)) {
      navigate(getRoutePath('onboardingBrandVoiceCreation'));
    }
  }, [locationState, navigate]);

  useTimeout(() => setShowConfetti(false), showConfettiSeconds * 1000);

  const { mutateAsync: createBrandVoice, isLoading: creationInProgress } = useMutation({
    mutationFn: (params: CreateBrandVoiceParams) => httpCreateBrandVoice.callEndpoint(params)
  });

  const { mutateAsync: setDefaultBrandVoice } = useSetDefaultBrandVoiceMutation();

  const completePersonalityTask = useCompleteTask(TASK_TYPE.CREATE_PERSONALITY);

  const handleNextClick = async () => {
    assertNonNullable(workspaceId, 'workspaceId is null');
    assertNonNullable(locationState.rest.brandVoice, 'brandVoice is null');
    GAEvents.onboardingPersonalitySaveClick();

    const thumbnailBase64 = image ?? (await fetchImageAsBase64(fallbackImage));

    const createdBrandVoice = await createBrandVoice({
      workspaceId,
      brandVoice: {
        ...locationState.rest.brandVoice,
        brand: locationState.rest.brand,
        thumbnailBase64: thumbnailBase64,
        active: true,
        personal: true
      }
    });
    if (!createdBrandVoice || !createdBrandVoice.id) {
      return;
    }

    completePersonalityTask();
    invalidatePersonalitiesQuery();

    setDefaultBrandVoice({
      brandVoiceId: createdBrandVoice.id
    });

    navigate(getRoutePath('home'));
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const isFooterButtonDisabled = creationInProgress;

  return (
    <>
      <OnboardingStepper currentStep="result">
        <BrandVoiceCard {...locationState} image={image} setImage={setImage} />

        <OnboardingFooter
          enableMobileLayout={false}
          nextButtonProps={{
            onClick: handleNextClick,
            disabled: isFooterButtonDisabled,
            startIcon: creationInProgress ? <CircularProgress size={24} /> : undefined
          }}
          nextButtonChildren={
            <FormattedMessage id="onboarding.personality_creation.finished.next" />
          }
          skipButtonProps={
            creationInProgress
              ? undefined
              : {
                  onClick: handleBackClick,
                  disabled: isFooterButtonDisabled
                }
          }
          skipButtonChildren={
            <FormattedMessage id="onboarding.personality_creation.finished.back" />
          }
        />
      </OnboardingStepper>

      <Confetti width={width} height={height} numberOfPieces={1000} recycle={showConfetti} />
    </>
  );
};

import { CircularProgress, Divider, MenuProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { InfiniteDropdownIntersectionObserver } from 'components/InfiniteDropdownIntersectionObserver';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { useChatCurrentGptModel } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useResetChat } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetChat';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import { useUpdateDocumentBrandVoice } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateDocumentBrandVoice';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { EMPTY_OPTION } from 'features/brand-voice/components/AutocompleteDropdownMenuContent';
import { BrandVoiceMenuItemContent } from 'features/brand-voice/components/BrandVoiceMenuItemContent';
import { CreateBrandVoiceMenuItemContent } from 'features/brand-voice/components/CreateBrandVoiceMenuItemContent';
import { useBrandVoiceAutocompleteData } from 'features/brand-voice/useBrandVoiceAutocompleteData';
import { useGetDocumentBrandVoiceModel } from 'features/brand-voice/useGetDocumentBrandVoiceModel';
import { useRedirectToBrandVoiceCreation } from 'features/brand-voice/useRedirectToBrandVoiceCreation';
import { useSetDefaultBrandVoiceMutation } from 'features/brand-voice/useSetDefaultBrandVoiceMutation';
import { MouseEventHandler, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  children: (open: MouseEventHandler<HTMLElement>) => ReactElement;

  dropDownProps?: Omit<MenuProps, 'open'>;
  onSelect?: () => void;
};

export const DocumentTopBarBrandVoiceButtonMenu = ({
  children,
  dropDownProps,
  onSelect
}: Props) => {
  const activeTab = useAppSelector(getActiveTab);
  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const { data: currentBrandVoice } = useGetDocumentBrandVoiceModel();

  const updateDocumentBrandVoice = useUpdateDocumentBrandVoice();

  const resetChat = useResetChat();
  const warnAboutChatReset = useWarnAboutChatReset();
  const gptModel = useChatCurrentGptModel();

  const { isLoading, brandVoices, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useBrandVoiceAutocompleteData({
      initialBrandVoiceId: activeTab.generateTextConfig.brandVoiceId ?? undefined,
      targetLanguage: currentModelLanguage,
      targetCountry: currentModelCountry
    });

  const { anchorElement, isOpen, open, close } = useDropdown();

  const { mutate: setDefaultBrandVoice } = useSetDefaultBrandVoiceMutation();
  const redirectToBrandVoiceCreation = useRedirectToBrandVoiceCreation();

  const onCreateClick = () => {
    redirectToBrandVoiceCreation();
    close();
  };

  const handleMenuItemClick = async (brandVoiceId: string | null) => {
    onSelect?.();
    close();

    warnAboutChatReset({
      onAccept: () => {
        resetChat({
          personalityId: activeTab.generateTextConfig.personalityId ?? null,
          brandVoiceId,
          gptModel,
          callback: () => {
            if (brandVoiceId) {
              setDefaultBrandVoice({ brandVoiceId });
            }

            updateDocumentBrandVoice({ brandVoiceId });
          }
        });
      }
    });
  };

  if (isLoading) {
    return <CircularProgress size="small" />;
  }

  return (
    <StyledDropdown
      trigger={children(open)}
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      width="auto"
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
      {...dropDownProps}
    >
      <MenuItem onClick={onCreateClick} value="create">
        <CreateBrandVoiceMenuItemContent />
      </MenuItem>

      <Divider key="divider-1" />

      <MenuItem
        key={EMPTY_OPTION.id}
        value={EMPTY_OPTION.id}
        onClick={() => handleMenuItemClick(null)}
        selected={!currentBrandVoice?.id}
      >
        <FormattedMessage id={EMPTY_OPTION.name} />
      </MenuItem>

      {brandVoices.map(brandVoice => (
        <MenuItem
          key={brandVoice.id ?? Math.random()}
          value={brandVoice.id || ''}
          selected={currentBrandVoice?.id === brandVoice.id}
          onClick={() => handleMenuItemClick(brandVoice.id ?? null)}
        >
          <BrandVoiceMenuItemContent option={brandVoice} />
        </MenuItem>
      ))}

      <InfiniteDropdownIntersectionObserver
        onIsInView={() => fetchNextPage()}
        isAlreadyFetching={isFetchingNextPage}
        enabled={hasNextPage}
      />
    </StyledDropdown>
  );
};

const menuMaxHeight = '480px';

const StyledDropdown = styled(MenuDropdown)`
  max-height: ${menuMaxHeight};
`;

const gtmIds = {
  home: {
    toolCard: {
      aiWriter: 'GTM_home_toolCard_aiWriter',
      aiImages: 'GTM_home_toolCard_aiImages',
      testing: 'GTM_home_toolCard_testing',
      exploring: 'GTM_home_toolCard_exploring'
    },
    browserExtensionHint: 'GTM_home_browserExtensionHint',
    inviteTeammates: 'GTM_home_inviteTeammates',
    inviteTeammatesPopup: {
      upgrade: 'GTM_home_inviteTeammatesPopup_upgrade'
    },
    academyBanner: 'GTM_home_academyBanner',
    brandVoiceBanner: 'GTM_home_brandVoiceBanner'
  },
  aiImages: {
    productTile: 'GTM_aiImages_productTile',
    generatorPage: {
      generateButton: 'GTM_aiImages_generatorPage_generateButton',
      numberOfImagesSelect: 'GTM_aiImages_generatorPage_numberOfImagesSelect',
      qualitySelect: 'GTM_aiImages_generatorPage_qualitySelect',
      promptInput: 'GTM_aiImages_generatorPage_promptInput',
      exampleButton: 'GTM_aiImages_generatorPage_exampleButton',
      downloadButton: 'GTM_aiImages_generatorPage_downloadButton',
      addToNewDocumentButton: 'GTM_aiImages_generatorPage_addToNewDocumentButton',
      addToExistingDocumentButton: 'GTM_aiImages_generatorPage_addToExistingDocumentButton',
      optimizeButton: 'GTM_aiImages_generatorPage_optimizeButton',
      copyShareLinkButton: 'GTM_aiImages_generatorPage_copyShareLinkButton',
      upgradePopup: {
        upgradeButton: 'GTM_aiImages_generatorPage_upgradePopup_upgradeButton',
        maybeLaterButton: 'GTM_aiImages_generatorPage_upgradePopup_maybeLaterButton'
      }
    }
  },
  onboardingJourney: {
    openView: {
      choice: 'GTM_onboardingJourney_openView_choice',
      survey: 'GTM_onboardingJourney_openView_survey',
      productTour: 'GTM_onboardingJourney_openView_productTour'
    },
    skipSamplePromptView: 'GTM_onboardingJourney_openView_samplePrompt',
    survey: {
      next: 'GTM_onboardingJourney_survey_next'
    },
    startChat: {
      goToChat: 'GTM_onboardingJourney_startChat_goToChat'
    },
    checklist: {
      open: 'GTM_onboardingJourney_checklist_open'
    },
    inviteFriend: {
      next: 'GTM_onboardingJourney_inviteFriend_next'
    }
  },
  textTypesLibrary: {
    open: 'GTM_textTypesLibrary_open',
    selectTextType: 'GTM_textTypesLibrary_selectTextType',
    selectCategory: 'GTM_textTypesLibrary_selectCategory',
    openFromRightSidebar: 'GTM_textTypesLibrary_openFromRightSidebar',
    mostFrequentCategoryClickedInMenu: 'GTM_textTypesLibrary_mostFrequentCategoryClickedInMenu',
    mostRecentCategoryClickedInMenu: 'GTM_textTypesLibrary_mostRecentCategoryClickedInMenu',
    favoritesCategoryClickedInMenu: 'GTM_textTypesLibrary_favoritesCategoryClickedInMenu',
    trendingCategoryClickedInMenu: 'GTM_textTypesLibrary_trendingCategoryClickedInMenu',
    openFromMenu: 'GTM_textTypesLibrary_openFromMenu',
    mostFrequentCategoryClickedInModal: 'GTM_textTypesLibrary_mostFrequentCategoryClickedInModal',
    mostRecentCategoryClickedInModal: 'GTM_textTypesLibrary_mostRecentCategoryClickedInModal',
    favoritesCategoryClickedInModal: 'GTM_textTypesLibrary_favoritesCategoryClickedInModal',
    trendingCategoryClickedInModal: 'GTM_textTypesLibrary_trendingCategoryClickedInModal',
    allCategoryClickedInModal: 'GTM_textTypesLibrary_allCategoryClickedInModal'
  },
  templatesLibrary: {
    selectTemplate: 'GTM_templatesLibrary_selectTemplate',
    createTemplate: 'GTM_templatesLibrary_createTemplate',
    saveTemplate: 'GTM_templatesLibrary_saveTemplate',
    makePublic: 'GTM_templatesLibrary_makePublic',
    updated: 'GTM_templatesLibrary_updated'
  },
  personalitiesLibrary: {
    selectPersonality: 'GTM_templatesLibrary_selectPersonality',
    openNewPersonalityCreator: 'GTM_templatesLibrary_openNewPersonalityCreator',
    createPersonality: 'GTM_templatesLibrary_createPersonality',
    automatedCreationChoice: 'GTM_personalitiesLibrary_automatedCreationChoice',
    manualCreationChoice: 'GTM_personalitiesLibrary_manualCreationChoice',
    personalityGenerated: 'GTM_personalitiesLibrary_personalityGenerated',
    personalityCreated: 'GTM_personalitiesLibrary_personalityCreated',
    optimizePersonality: 'GTM_personalitiesLibrary_optimizePersonality',
    undoOptimizationPersonality: 'GTM_personalitiesLibrary_undoOptimizationPersonality',
    fromInformationCreationChoice: 'GTM_personalitiesLibrary_fromInformationCreationChoice',
    shareURLCreationChoice: 'GTM_personalitiesLibrary_shareURLCreationChoice'
  },
  verifyAccount: {
    goToEmail: 'GTM_verifyAccount_goToEmail',
    goToLogin: 'GTM_verifyAccount_goToLogin'
  },
  helpscoutBeacon: {
    open: 'GTM_helpscoutBeacon_open'
  },
  aiWriter: {
    advancedSettings: 'GTM_aiWriter_advancedSettings',
    changeBrief: 'GTM_aiWriter_changeBrief',
    createNewProject: 'GTM_aiWriter_createNewProject',
    editorCreateNewProject: 'GTM_aiWriter_editorCreateNewProject',
    newBlogPostBuilder: 'GTM_aiWriter_blogBuilder',
    newSocialPostBuilder: 'GTM_aiWriter_socialPostBuilder',
    newAIImages: 'GTM_aiWriter_aiImages',
    newAITemplates: 'GTM_aiWriter_aiTemplates',
    newContentIdeas: 'GTM_aiWriter_contentIdeas',
    newLinkedInPost: 'GTM_aiWriter_linkedinPost',
    newMarketingCampaign: 'GTM_aiWriter_marketingCampaign',
    newEnhancedBlogPost: 'GTM_aiWriter_enhancedBlogPost',
    newRewriteArticles: 'GTM_aiWriter_rewriteArticles',
    newProductLandingPage: 'GTM_aiWriter_productLandingPage',
    newNewsletter: 'GTM_aiWriter_newsletter',
    newSummarizeURL: 'GTM_aiWriter_summarizeURL',
    confirmBrief: 'GTM_aiWriter_confirmBrief',
    deleteFavorite: 'GTM_aiWriter_deleteFavorite',
    editBrief: 'GTM_aiWriter_editBrief',
    initializeProject: 'GTM_aiWriter_initializeProject',
    loadProject: 'GTM_aiWriter_loadProject',
    openTextInspirationsPanel: 'GTM_aiWriter_openTextInspirationsPanel',
    openYourFavoritesPanel: 'GTM_aiWriter_openYourFavoritesPanel',
    refreshAllTextInspirations: 'GTM_aiWriter_refreshAllTextInspirations',
    sortTextInspirations: 'GTM_aiWriter_sortTextInspirations',
    refreshSelectedTextInspiration: 'GTM_aiWriter_refreshSelectedTextInspiration',
    saveAsFavorite: 'GTM_aiWriter_saveAsFavorite',
    selectFavorite: 'GTM_aiWriter_selectFavorite',
    selectTextInspiration: 'GTM_aiWriter_selectTextInspiration',
    backToOverview: 'GTM_aiWriter_backToOverview',
    languageSelector: 'GTM_aiWriter_languageSelector',
    editDocumentName: 'GTM_aiWriter_editDocumentName',
    activeTabDropdown: 'GTM_aiWriter_activeTabDropdown',
    watchTutorial: 'GTM_aiWriter_watchTutorial',
    exportDocument: 'GTM_aiWriter_exportDocument',
    historyTab: 'GTM_aiWriter_historyTab',
    favoriteTab: 'GTM_aiWriter_favoriteTab',
    openInformationModal: 'GTM_aiWriter_openInformationModal',
    applyInformation: 'GTM_aiWriter_applyInformation',
    shareDocument: {
      team: 'GTM_aiWriter_share_team',
      public: 'GTM_aiWriter_share_public',
      copyLink: 'GTM_aiWriter_share_copyLink'
    },
    limitDropdown: {
      open: 'GTM_aiWriter_limitDropdown_open',
      upgrade: 'GTM_aiWriter_limitDropdown_upgrade',
      help: 'GTM_aiWriter_limitDropdown_help'
    },
    projectOverview: {
      chat: {
        selectModel: 'GTM_aiWriter_projectOverview_chat_selectModel',
        sendMessage: 'GTM_aiWriter_projectOverview_chat_sendMessage',
        openPromptLibrary: 'GTM_aiWriter_projectOverview_chat_openPromptLibrary',
        openInformationModal: 'GTM_aiWriter_projectOverview_chat_openInformationModal',
        applyInformation: 'GTM_aiWriter_projectOverview_chat_applyInformation'
      }
    },
    editor: {
      selectionToolbar: {
        rewrite: 'GTM_aiWriter_editor_selectionToolbar_rewrite',
        continueWriting: 'GTM_aiWriter_editor_selectionToolbar_continueWriting',
        expand: 'GTM_aiWriter_editor_selectionToolbar_expand',
        command: 'GTM_aiWriter_editor_selectionToolbar_command',
        improve: 'GTM_aiWriter_editor_selectionToolbar_improve',
        summarize: 'GTM_aiWriter_editor_selectionToolbar_summarize',
        flashActions: 'GTM_aiWriter_editor_selectionToolbar_flashActions',
        createFlashAction: 'GTM_aiWriter_editor_selectionToolbar_createFlashAction',
        translate: 'GTM_aiWriter_editor_selectionToolbar_translate',
        repurpose: 'GTM_aiWriter_editor_selectionToolbar_repurpose',
        repurposeTwitter: 'GTM_aiWriter_editor_selectionToolbar_repurposeTwitter',
        repurposeFacebook: 'GTM_aiWriter_editor_selectionToolbar_repurposeFacebook',
        repurposeLinkedIn: 'GTM_aiWriter_editor_selectionToolbar_repurposeLinkedIn',
        formalize: 'GTM_aiWriter_editor_selectionToolbar_formalize',
        executeFlashAction: 'GTM_aiWriter_editor_selectionToolbar_executeFlashAction'
      },
      continueWriting: 'GTM_aiWriter_editor_continueWriting',
      floatingToolbar: {
        flashActions: 'GTM_aiWriter_editor_floatingToolbar_flashActions'
      }
    },
    onboarding: {
      backToSurvey: 'GTM_aiWriter_onboarding_backToSurvey',
      generateExample: 'GTM_aiWriter_onboarding_generateExample',
      sendPrompt: 'GTM_aiWriter_onboarding_sendPrompt',
      skip: 'GTM_aiWriter_onboarding_skip'
    },
    tour: {
      predefinedChip: 'GTM_aiWriter_tour_predefinedChip'
    },
    share: {
      button: 'GTM_aiWriter_share_button',
      enable: 'GTM_aiWriter_share_enable',
      copy: 'GTM_aiWriter_share_copy',
      exportHTML: 'GTM_aiWriter_share_exportHTML',
      exportDOCX: 'GTM_aiWriter_share_exportDOCX'
    },
    download: {
      pdf: 'GTM_aiWriter_download_pdf',
      docx: 'GTM_aiWriter_download_docx'
    },
    images: {
      openTab: 'GTM_aiWriter_images_openTab',
      selectImage: 'GTM_aiWriter_images_selectImage',
      clickOnAuthorName: 'GTM_aiWriter_images_clickOnAuthorName',
      download: 'GTM_aiWriter_images_download',
      loadMore: 'GTM_aiWriter_images_loadMore'
    },
    seo: {
      openTab: 'GTM_aiWriter_seo_openTab',
      analyse: 'GTM_aiWriter_seo_analyse',
      refresh: 'GTM_aiWriter_seo_refresh',
      startOver: 'GTM_aiWriter_seo_startOver',
      addSERP: 'GTM_aiWriter_seo_addSERP',
      analysisTFIDF: 'GTM_aiWriter_seo_TFIDF',
      analysisSERP: 'GTM_aiWriter_seo_SERP'
    },
    chat: {
      openTab: 'GTM_aiWriter_chat_openTab',
      sendMessage: 'GTM_aiWriter_chat_sendMessage',
      reset: 'GTM_aiWriter_chat_reset',
      saveAsFavorite: 'GTM_aiWriter_chat_saveAsFavorite',
      copyToClipboard: 'GTM_aiWriter_chat_copyToClipboard',
      selectChatReply: 'GTM_aiWriter_chat_selectChatReply',
      openTemplatesLibrary: 'GTM_aiWriter_chat_openTemplatesLibrary',
      selectExample: 'GTM_aiWriter_chat_selectExample',
      selectPersonalityViaDropdown: 'GTM_aiWriter_chat_selectPersonalityViaDropdown',
      openPersonalitiesLibrary: 'GTM_aiWriter_chat_openPersonalitiesLibrary',
      openFlashActions: 'GTM_aiWriter_chat_openFlashActions',
      openFlashActionsButton: 'GTM_aiWriter_chat_openFlashActionsButton',
      createFlashAction: 'GTM_aiWriter_chat_createFlashAction',
      rewriteFlashAction: 'GTM_aiWriter_chat_rewriteFlashAction',
      continueFlashAction: 'GTM_aiWriter_chat_continueFlashAction',
      improveFlashAction: 'GTM_aiWriter_chat_improveFlashAction',
      summarizeFlashAction: 'GTM_aiWriter_chat_summarizeFlashAction',
      customFlashAction: 'GTM_aiWriter_chat_customFlashAction',
      openGptSelect: 'GTM_aiWriter_chat_openGptSelect',
      selectGptModel: 'GTM_aiWriter_chat_selectGptModel',
      gptLimitReachedContinue: 'GTM_aiWriter_chat_gptLimitReachedContinue',
      optimizePrompt: 'GTM_aiWriter_chat_optimizePrompt',
      undoOptimizePrompt: 'GTM_aiWriter_chat_undoOptimizePrompt',
      executeSearchQuery: 'GTM_aiWriter_chat_executeSearchQuery',
      openInformationModal: 'GTM_aiWriter_chat_openInformationModal',
      applyInformation: 'GTM_aiWriter_chat_applyInformation',
      scrollChat: 'GTM_aiWriter_chat_scrollChat'
    },
    templates: {
      openTab: 'GTM_aiWriter_templates_openTab',
      selectTemplate: 'GTM_aiWriter_templates_selectTemplate',
      openLibrary: 'GTM_aiWriter_templates_openLibrary',
      moreTemplates: 'GTM_aiWriter_templates_moreTemplates'
    },
    plagiarism: {
      openTab: 'GTM_aiWriter_plagiarism_openTab',
      scanDocument: 'GTM_aiWriter_plagiarism_scanDocument',
      scanSelectedText: 'GTM_aiWriter_plagiarism_scanSelectedText',
      compareText: 'GTM_aiWriter_plagiarism_compareText',
      itemLink: 'GTM_aiWriter_plagiarism_itemLink'
    },
    history: {
      openTab: 'GTM_aiWriter_history_openTab'
    },
    tags: {
      allTags: 'GTM_aiWriter_tags_allTags',
      mostRecentTags: 'GTM_aiWriter_tags_mostRecentTags',
      tagsByMe: 'GTM_aiWriter_tags_tagsByMe',
      tagsByTeam: 'GTM_aiWriter_tags_tagsByTeam',
      newTagView: 'GTM_aiWriter_tags_newTagView'
    },
    flashScore: {
      openTab: 'GTM_aiWriter_flashScore_openTab',
      bottomToolbar: 'GTM_aiWriter_flashScore_bottomToolbar',
      topToolbar: 'GTM_aiWriter_flashScore_topToolbar',
      floatingToolbar: 'GTM_aiWriter_flashScore_floatingToolbar',
      oneClickOptimizeCta: 'GTM_aiWriter_flashScore_oneClickOptimizeCta',
      oneClickOptimizeInsertCta: 'GTM_aiWriter_flashScore_oneClickOptimizeInsertCta',
      oneClickOptimizeReplaceCta: 'GTM_aiWriter_flashScore_oneClickOptimizeReplaceCta',
      oneClickOptimizeRefreshCta: 'GTM_aiWriter_flashScore_oneClickOptimizeRefreshCta',
      getSuggestionsCta: 'GTM_aiWriter_flashScore_getSuggestionsCta',
      selectSuggestionType: 'GTM_aiWriter_flashScore_selectSuggestionType',
      emotionalityHighlightedWord: 'GTM_aiWriter_flashScore_emotionalityHighlightedWord',
      refreshSynonyms: 'GTM_aiWriter_flashScore_refreshSynonyms',
      refreshScoring: 'GTM_aiWriter_flashScore_refreshScoring'
    }
  },
  blogPostBuilder: {
    primaryAction: 'GTM_blogPostBuilder_nextStep',
    secondaryAction: 'GTM_blogPostBuilder_secondaryAction',
    selectTextInspiration: 'GTM_blogPostBuilder_selectTextInspiration',
    tonality: 'GTM_blogPostBuilder_tonality'
  },
  socialPostBuilder: {
    socialChannel: 'GTM_socialPostBuilder_socialChannel',
    nextButton: 'GTM_socialPostBuilder_nextStep',
    backButton: 'GTM_socialPostBuilder_previousStep',
    tonality: 'GTM_socialPostBuilder_tonality',
    generate: 'GTM_socialPostBuilder_generate'
  },
  subjectLines: {
    selectSuggestionListItem: 'GTM_subjectLines_selectSuggestion_listItem',
    deleteSuggestion: 'GTM_subjectLines_deleteSuggestion',
    selectSuggestionArrowButton: 'GTM_subjectLines_selectSuggestion_arrowButton',
    deleteSelection: 'GTM_subjectLines_deleteSelection',
    editSelection: 'GTM_subjectLines_editSelection',
    newCampaignCreated: 'GTM_subjectLines_newCampaignCreated',
    refreshSuggestions: 'GTM_subjectLines_refreshSuggestions',
    goToThirdStep: 'GTM_subjectLines_goToThirdStep',
    saveAndConfirm: 'GTM_subjectLines_saveAndConfirm'
  },
  pricing: {
    upgradePopUp: 'GTM_upgradePricing_dialog',
    pay: 'GTM_pricing_pay',
    updatePaymentInformation: 'GTM_pricing_updatePaymentInformation',
    updatePaymentInformationConfirm: 'GTM_pricing_updatePaymentInformationConfirm',
    success_modal: 'GTM_pricing_success_modal',
    wordsLimitReachedFreePlan: 'GTM_pricing_wordsLimitReachedFreePlan',
    wordsLimitReachedBasicPlan: 'GTM_pricing_wordsLimitReachedBasicPlan',
    cancelSubscription: 'GTM_pricing_cancelSubscription',
    deleteAccount: 'GTM_pricing_deleteAccount'
  },
  sidebar: {
    referralBonusPageButton: 'GTM_sidebar_referralBonusPageButton',
    neuroflashIcon: 'GTM_sidebar_neuroflashIcon',
    contentFlash: 'GTM_sidebar_contentFlash',
    imageFlash: 'GTM_sidebar_imageFlash',
    performanceFlash: 'GTM_sidebar_performanceFlash',
    researchFlash: 'GTM_sidebar_researchFlash',
    notifications: 'GTM_sidebar_notifications',
    notificationsModal: {
      clickAway: 'GTM_sidebar_notifications_clickAway',
      academy: 'GTM_sidebar_notificationsModal_academy'
    },
    workflowsItem: 'GTM_sidebar_workflowsItem',
    help: 'GTM_sidebar_help',
    accountMenu: 'GTM_sidebar_accountMenu',
    accountMenuItem: 'GTM_sidebar_accountMenuItem',
    upgrade: 'GTM_sidebar_upgrade',
    inviteTeammates: 'GTM_sidebar_inviteTeammates',
    learningCenter: {
      gettingStarted: 'GTM_sidebar_learningCenter_gettingStarted',
      videoTutorials: 'GTM_sidebar_learningCenter_videoTutorials',
      helpCenter: 'GTM_sidebar_learningCenter_helpCenter',
      liveWebinars: 'GTM_sidebar_learningCenter_liveWebinars',
      neuroflashAcademy: 'GTM_sidebar_learningCenter_neuroflashAcademy',
      productUpdates: 'GTM_sidebar_learningCenter_productUpdates',
      requestFeature: 'GTM_sidebar_learningCenter_requestFeature',
      contactSupport: 'GTM_sidebar_learningCenter_contactSupport'
    },
    academy: 'GTM_sidebar_academy'
  },
  tester: {
    startNewProject: 'GTM_testing_startNewProject',
    selectSuggestion_listItem: 'GTM_testing_selectSuggestion_listItem',
    selectSuggestion_plusButton: 'GTM_testing_selectSuggestion_plusButton',
    copySelection: 'GTM_testing_CopySelection',
    deleteSelection: 'GTM_testing_deleteSelection',
    deleteSuggestion: 'GTM_testing_deleteSuggestion',
    rewriteSelection: 'GTM_testing_rewriteSelection',
    optimizeSelection: 'GTM_testing_optimizeSelection',
    rewriteSuggestion: 'GTM_testing_rewriteSuggestion',
    rewriteAllSuggestions: 'GTM_testing_rewriteAllSuggestions',
    outputType: 'GTM_testing_outputType',
    editBriefConfig: 'GTM_testing_editBriefConfig',
    editGoalConfig: 'GTM_testing_editGoalConfig',
    optimizeGoal: 'GTM_testing_optimizeGoal',
    openInformationModal: 'GTM_testing_openInformationModal',
    applyInformation: 'GTM_testing_applyInformation',
    loadProject: 'GTM_testing_loadProject'
  },
  modals: {
    periodSwitcher: 'GTM_modals_periodSwitcher',
    bonusLink: 'GTM_modals_bonusLink',
    pricingDetailsLink: 'GTM_modals_pricingDetailsLink',
    featuresComparisonTable: 'GTM_featuresComparisonTable'
  },
  warningModal: {
    goToNF: 'GTM_warningModal_goToNF',
    courses: 'GTM_warningModal_courses',
    close: 'GTM_warningModal_close'
  },
  profile: {
    users: {
      infoCard: {
        inviteTeammates: 'GTM_profile_users_infoCard_inviteTeammates',
        upgrade: 'GTM_profile_users_infoCard_upgrade'
      }
    }
  },
  workflows: {
    overview: {
      create: 'GTM_workflows_overview_create',
      toggle: 'GTM_workflows_overview_toggle',
      delete: 'GTM_workflows_overview_delete'
    }
  },
  brandHub: {
    information: {
      createdInformation: 'GTM_flashHub_information_createdInformation',
      createdInformationFromCopyPaste: 'GTM_flashHub_information_createdInformationFromCopyPaste',
      createdInformationFromShareURL: 'GTM_flashHub_information_createdInformationFromShareURL',
      createdInformationFromUpload: 'GTM_flashHub_information_createdInformationFromUpload',
      attemptToCreateInformation: 'GTM_flashHub_information_attemptToCreateInformation',
      upgrade: 'GTM_flashHub_information_upgrade',
      updated: 'GTM_flashHub_information_updated',
      deletedInformationCategory: 'GTM_flashHub_information_deletedInformationCategory'
    },
    templates: {
      intentToCreateTemplate: 'GTM_flashHub_templates_intentToCreateTemplate'
    },
    brandVoice: {
      intentToCreateBrandVoice: 'GTM_flashHub_brandVoice_intentToCreateBrandVoice',
      create: 'GTM_flashHub_brandVoice_create',
      upgrade: 'GTM_flashHub_brandVoice_upgrade',
      updated: 'GTM_flashHub_brandVoice_updated'
    }
  }
};

export default gtmIds;

import { IFRAME_ID } from 'features/brand-hub-v2/newAppPostMessage';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { useEffect, useState } from 'react';
import { authService } from 'services/auth/AuthService';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useAppThemeStore } from 'styles/new-theme/useAppThemeStore';

const iframeBaseUrl = process.env.REACT_APP_NEXTJS_URL;

export const BrandHubBrandVoiceIframeWrapper = () => {
  const themeMode = useAppThemeStore(state => state.themeMode);
  const currentLanguage = useAppSelector(getCurrentLanguage);

  const [localAuthToken, setLocalAuthToken] = useState<string | null>(authService.getAccessToken());
  const [url, setUrl] = useState<string | null>(null);

  const brandVoiceBasePath = location.pathname;

  // Refresh local state on token change, to make sure the iframe is reloaded with the new token
  const authToken = authService.getAccessToken();

  useEffect(() => {
    setLocalAuthToken(authToken);
  }, [authToken]);

  useEffect(() => {
    const newUrl = new URL(`${iframeBaseUrl}${brandVoiceBasePath}`);
    newUrl.searchParams.set('isEmbedded', 'true');
    newUrl.searchParams.set('theme-mode', themeMode);
    newUrl.searchParams.set('auth-token', localAuthToken || '');
    newUrl.searchParams.set('language', currentLanguage || AppLanguage.English);
    setUrl(newUrl.toString());
  }, [brandVoiceBasePath, currentLanguage, localAuthToken, themeMode]);

  if (!url) {
    return null;
  }

  return (
    <StyledIframe
      id={IFRAME_ID}
      title="Brand Hub Brand Voice App"
      src={url}
      $isActive={true}
    ></StyledIframe>
  );
};

const StyledIframe = styled.iframe<{ $isActive: boolean }>`
  border: none;
  width: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
  height: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
`;

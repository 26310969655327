import { Tune } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Divider, ListItemText, MenuProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FlexContainer from 'components/FlexContainer';
import { InfiniteDropdownIntersectionObserver } from 'components/InfiniteDropdownIntersectionObserver';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { MissingOption } from 'components/personality/MissingOption';
import { PersonalityWrapper } from 'components/personality/PersonalityWrapper';
import { createOption, manageOption, noPersonalityOption } from 'components/personality/types';
import { useChatCurrentGptModel } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useDocumentPersonalityData } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDocumentPersonalityData';
import { useResetChat } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetChat';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetDefaultBrandVoice } from 'features/brand-voice/useGetDefaultBrandVoice';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MouseEventHandler, ReactElement } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  children: (open: MouseEventHandler<HTMLElement>) => ReactElement;

  dropDownProps?: Omit<MenuProps, 'open'>;
  onSelect?: () => void;
};

export function PersonalityButton(props: Props) {
  const updateDocumentPersonality = useUpdateAiWriterAndDefaultPersonality();
  const resetChat = useResetChat();
  const warnAboutChatReset = useWarnAboutChatReset();
  const { data: defaultBrandVoice } = useGetDefaultBrandVoice();

  const gptModel = useChatCurrentGptModel();

  const { anchorElement, isOpen, open, close } = useDropdown();

  const activeTab = useAppSelector(getActiveTab);
  const { embeddingModelId } = activeTab;

  const { data: currentPersonalityData } = useDocumentPersonalityData();

  const handleChange = (newPersonality: PersonalityDto | null | undefined) => {
    // checking only for undefined because null is a valid value when a user wants to select "Empty" personality
    if (newPersonality === undefined) {
      return;
    }

    close();

    warnAboutChatReset({
      onAccept: () => {
        resetChat({
          personalityId: newPersonality?.id ?? null,
          brandVoiceId: defaultBrandVoice?.brandVoiceId ?? null,
          gptModel,
          callback: () => {
            updateDocumentPersonality({ personality: newPersonality });
          }
        });
      }
    });
  };

  return (
    <PersonalityWrapper
      languageModelId={embeddingModelId}
      value={currentPersonalityData ?? null}
      onChange={handleChange}
    >
      {({
        personalities,
        handleMenuOptionChange,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
      }) => (
        <StyledDropdown
          trigger={props.children(open)}
          isOpen={isOpen}
          anchorElement={anchorElement}
          close={close}
          width="auto"
          anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
          transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
          {...props.dropDownProps}
        >
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={manageOption}
            onClick={() => {
              props.onSelect?.();
              handleMenuOptionChange(manageOption);
              close();
            }}
          >
            <Tune />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_manage_option" />
            </ListItemText>
          </SelectItem>
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={createOption}
            onClick={() => {
              props.onSelect?.();
              handleMenuOptionChange(createOption);
              close();
            }}
          >
            <AddIcon />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_create_option" />
            </ListItemText>
          </SelectItem>
          <Divider />
          {personalities?.map(personality => (
            <SelectItem
              {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
              key={personality.id}
              value={personality.id}
              selected={
                currentPersonalityData ? currentPersonalityData.id === personality.id : false
              }
              onClick={() => {
                props.onSelect?.();
                handleMenuOptionChange(personality.id);
                close();
              }}
            >
              <FlexContainer direction="row">
                <ListItemText>{personality.label}</ListItemText>
              </FlexContainer>
            </SelectItem>
          ))}
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
            value={noPersonalityOption}
            selected={currentPersonalityData ? currentPersonalityData.id === null : true}
            onClick={() => {
              props.onSelect?.();
              handleMenuOptionChange(noPersonalityOption);
              close();
            }}
          >
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_no_personality_option" />
            </ListItemText>
          </SelectItem>
          <MissingOption
            currentModelId={embeddingModelId}
            currentPersonality={currentPersonalityData ?? null}
            personalities={personalities}
          />
          <InfiniteDropdownIntersectionObserver
            onIsInView={() => fetchNextPage()}
            isAlreadyFetching={isFetchingNextPage}
            enabled={hasNextPage}
          />
        </StyledDropdown>
      )}
    </PersonalityWrapper>
  );
}

const SelectItem = styled(MenuItem)`
  gap: ${({ theme }) => theme.spacings.small};
`;

export const personalityMenuMaxHeight = '480px';

const StyledDropdown = styled(MenuDropdown)`
  max-height: ${personalityMenuMaxHeight};
`;

import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { ComponentProps, MouseEvent } from 'react';
import { useLocation } from 'react-router';

type SidebarItemProps = ComponentProps<typeof SidebarItem>;

type Props = SidebarItemProps & {
  to: NonNullable<SidebarItemProps['to']>;
  requireWildcardSubRouteMatching?: boolean;
};

export const SidebarNavItem = ({ to, requireWildcardSubRouteMatching, ...props }: Props) => {
  const location = useLocation();
  const isActive = requireWildcardSubRouteMatching
    ? // In case of sub routes, we've to check if the current URL starts with the to path
      location.pathname.startsWith(to)
    : to.startsWith(location.pathname);
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    toggleSidebar();

    props.onClick?.(event);
  };

  return <SidebarItem isActive={isActive} to={to} onClick={handleOnClick} {...props} />;
};

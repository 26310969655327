import { useQuery } from '@tanstack/react-query';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { createEmptyScoreTextsResult } from 'features/flashScore/createScoreTextsResult';
import { ReadabilityAndEmotionalityGrade } from 'features/flashScore/getEmotionalityTranslationLabel';
import { useScoreTextsMutation } from 'features/flashScore/useScoreTextsMutation';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { useAppSelector } from 'store/hooks';

export type FlashScoreTextRatingResult = {
  text: string;
  readability: FlashScoreTextRating;
  emotionality: FlashScoreTextRating;
};

export type FlashScoreTextRating = {
  grade: ReadabilityAndEmotionalityGrade;
  score: number;
  percentage: number;
  label: IntlMessageKeys;
};

type Options = {
  texts: string[];
  cacheKeys?: string[];
  enabled?: boolean;
};

export const useScoreTexts = (options: Options) => {
  const { embeddingModelId } = useAppSelector(getActiveTab);
  const { mutateAsync: runScoringMutation } = useScoreTextsMutation();

  const emptyPlaceholderData = options.texts.map(text => createEmptyScoreTextsResult(text));

  const hasNonEmptyTexts = options.texts.some(text => text.trim().length > 0);

  return useQuery<FlashScoreTextRatingResult[]>({
    enabled: !!embeddingModelId && hasNonEmptyTexts && options?.enabled !== false,
    queryKey: [
      ...makeCurrentUserQueryKeyPrefix(),
      'flashScore',
      'texts',
      embeddingModelId,
      ...(options.cacheKeys ?? [])
    ],
    keepPreviousData: true,
    // Use empty data as a placeholder initially to avoid flickering
    placeholderData: emptyPlaceholderData,
    queryFn: async () => runScoringMutation(options)
  });
};

import { ModalType } from 'components/modals/modalComponents';

const iframeBaseUrl = process.env.REACT_APP_NEXTJS_URL;

export const IFRAME_ID = 'brand-voice-iframe';

type OutgoingNavigationMessage = {
  type: 'nav';
  path: string;
};

type OutgoingModalResultMessage = {
  type: 'modal-result';
  data: Record<string, unknown> & {
    modalId: string;
  };
};

type OutgoingMessage = OutgoingNavigationMessage | OutgoingModalResultMessage;

const newAppPostMessage = (message: OutgoingMessage) => {
  const iFrame = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
  iFrame?.contentWindow?.postMessage(message, iframeBaseUrl);
};

export const navigateInIframe = (path: string) => {
  newAppPostMessage({ type: 'nav', path });
};

export const sendModalResult = (modalId: ModalType, data: Record<string, unknown>) => {
  newAppPostMessage({
    type: 'modal-result',
    data: {
      modalId,
      ...data
    }
  });
};

import { Button, Chip, LinearProgress, Paper, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { EditableImage } from 'features/onboardingJourney/components/EditableImage';
import { OnboardingBrandVoiceTextExtractionLocationState } from 'features/onboardingJourney/new-onboarding-2025/onboarding-creation-new';
import { GeneralTone } from 'features/onboardingJourney/utils/mapRecordToCharacteristics';
import { uniqueId } from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { NewColorConfig } from 'styles/new-theme/newThemeConfig';

type BrandVoiceCardProps = OnboardingBrandVoiceTextExtractionLocationState & {
  image: string | undefined;
  setImage: (image: string) => void;
};

export const BrandVoiceCard = ({
  brandVoiceName,
  brandName,
  website,
  description,
  generalTone,
  keywords,
  image,
  setImage
}: BrandVoiceCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledPaper variant="outlined" $isExpanded={isExpanded}>
      <FlexContainer gap="three">
        <CardContentGrid>
          <EditableImage image={image} setImage={setImage} />

          <ContentContainer>
            <StyledTypography variant="h5">{brandVoiceName}</StyledTypography>
            <StyledTypography variant="body2" $color="textSecondary">
              {brandName}
            </StyledTypography>
            <StyledTypography variant="body2" $color="textSecondary" fontStyle="italic">
              {website}
            </StyledTypography>
          </ContentContainer>
        </CardContentGrid>

        <FlexContainer gap="one">
          <StyledTypography variant="body2" fontWeight={700}>
            <FormattedMessage id="common.description" />
          </StyledTypography>
          <TruncatedDescription variant="body1" $truncate={!isExpanded}>
            {description}
          </TruncatedDescription>
        </FlexContainer>

        <GenralToneSection generalTone={generalTone} />

        <KeywordSection keywords={keywords} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </FlexContainer>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $isExpanded?: boolean }>`
  padding: ${({ theme }) => theme.spacings.four};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  min-height: 600px;
  max-height: ${({ $isExpanded }) => ($isExpanded ? '1500px' : '700px')};
  width: 450px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
`;

const CardContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${({ theme }) => theme.spacings.four};
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.one};
  overflow: hidden;
`;

const TruncatedDescription = styled(Typography)<{ $truncate?: boolean }>`
  text-align: justify;

  ${({ $truncate }) => {
    if ($truncate) {
      return css`
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s ease-out;
      `;
    }

    return css`
      overflow: auto;
      transition: all 0.3s ease-out;
    `;
  }}
`;

const StyledTypography = styled(Typography)<{
  $color?: keyof NewColorConfig;
  $fontWeight?: string;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  && {
    color: ${({ theme, $color }) => ($color !== undefined ? theme.colors[$color] : 'inherit')};
    font-weight: ${({ $fontWeight }) => $fontWeight};
  }
`;

type GenralToneSectionProps = {
  generalTone: GeneralTone[];
};

const GenralToneSection = ({ generalTone }: GenralToneSectionProps) => (
  <FlexContainer gap="one">
    <StyledTypography variant="body2" fontWeight={700}>
      <FormattedMessage id="brand_hub.brand_voice.profile.characteristics.title" />
    </StyledTypography>
    <BrandVCharacteristicsGird>
      {generalTone?.map(tone => (
        <Fragment key={uniqueId()}>
          <StyledTypography variant="body2">{tone.label}</StyledTypography>
          <ProgressBarContainer>
            <StyledLinearProgress
              value={tone.value * 100}
              variant="determinate"
              color="secondary"
            />
            <PercentageTypography variant="body2">
              {Math.round(tone.value * 100)}%
            </PercentageTypography>
          </ProgressBarContainer>
        </Fragment>
      ))}
    </BrandVCharacteristicsGird>
  </FlexContainer>
);

const StyledLinearProgress = styled(LinearProgress)`
  flex-grow: 1;
  height: 6px;
  border-radius: ${({ theme }) => theme.borderRadius.one};

  & .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.colors.secondaryColorDark};
  }
`;

const BrandVCharacteristicsGird = styled('div')`
  display: grid;
  grid-template-columns: minmax(100px, auto) 1fr;
  gap: ${({ theme }) => theme.spacings.two};
  align-items: center;
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.two};
`;

const PercentageTypography = styled(Typography)`
  text-align: right;
`;

type KeywordSectionProps = {
  keywords: string[];
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
};

const KeywordSection = ({ keywords, isExpanded, setIsExpanded }: KeywordSectionProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const isContentOverflowing =
          containerRef.current.scrollHeight > containerRef.current.clientHeight;
        setIsOverflowing(isContentOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [keywords]);

  return (
    <FlexContainer gap="one">
      <StyledTypography variant="body2" fontWeight={700}>
        <FormattedMessage id="brand_hub.brand_voice.profile.keywords.title" />
      </StyledTypography>
      <KeywordsContainer $isExpanded={isExpanded} ref={containerRef}>
        {keywords.map(keyword => (
          <StyledChip key={keyword} label={keyword} size="small" />
        ))}
      </KeywordsContainer>
      {isOverflowing && (
        <ToggleButton variant="text" size="small" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Collapse' : 'See all'}
        </ToggleButton>
      )}
    </FlexContainer>
  );
};

const KeywordsContainer = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.two};
  max-height: ${({ $isExpanded }) => ($isExpanded ? '1000px' : '60px')};
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  transition: max-height 0.3s ease-out;
`;

const ToggleButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacings.one};
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.commonBlackMain};
`;

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.commonBrandColorsBrandDark};
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

import { collapseSelection, insertText } from '@udecode/plate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useGenerateTextInDocument } from 'features/aiWriter/AiWriterTextEditor/hooks/useGenerateTextInDocument';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';

const whitespaceRegex = /\s$/;

const useQuickActionInEditor = () => {
  const editor = useEditor();
  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);

  const generateMutation = useGenerateTextInDocument({
    outputType: 'quick_action',
    toasts: {
      loading: <FormattedMessage id="aiWriter.expand.toast_start" />,
      success: <FormattedMessage id="aiWriter.expand.toast_end" />,
      error: <FormattedMessage id="aiWriter.expand.toast_end.failed" />
    }
  });

  return async ({
    text,
    promptParam,
    outputType
  }: {
    text: string;
    promptParam?: string;
    outputType?: string;
  }) => {
    if (!editor) {
      return;
    }

    const endsWithWhitespace = whitespaceRegex.test(text);
    const trimmedText = text.trim();

    // Clear selection before endpoint call to close toolbar
    if (editor.selection !== null) {
      collapseSelection(editor, { edge: 'end' });
    }

    // Clear fake selection mark
    clearFakeSelection(editor);

    if (!endsWithWhitespace) {
      insertText(editor, ' ');
    }

    const truncatedSearchText = trimmedText;

    trackingWrapper.track('aiWriterFlashActionInputCreateWithAiAction', {
      documentId: tabId,
      text: truncatedSearchText,
      audienceModelId: generateTextConfig.audienceId,
      prompt: promptParam
    });

    await generateMutation.mutateAsync({ text: truncatedSearchText, outputType });
  };
};

export default useQuickActionInEditor;

import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import { usePreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

type ModelAndAudience = {
  modelId: string;
  audienceId: number;
};

export const useModelAndAudience = () => {
  const [modelAndAudience, setModelAndAudience] = useState<ModelAndAudience>();
  const preferencesResult = usePreferredLocaleQuery();
  const preferredLocale = preferencesResult.data;
  const language = preferredLocale?.language ?? fallbackModelLanguage;
  const country = preferredLocale?.country ?? fallbackModelCountry;
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const initialModelId = models.find(
    model => model.language === language && model.country === country
  )?.id;
  const getAudienceByLanguageAndCountry = useAppSelector(getGetAudienceByLanguageAndCountry);
  const audiences = getAudienceByLanguageAndCountry(language, country);
  const initialAudienceId = getPreferredAudience({ audiences, locale: { language, country } })?.id;

  const canSetInitialData = !modelAndAudience && preferencesResult.isFetched;

  useEffect(() => {
    if (!canSetInitialData) {
      return;
    }

    setModelAndAudience({
      modelId: initialModelId ?? '',
      audienceId: initialAudienceId ?? 0
    });
  }, [canSetInitialData, initialAudienceId, initialModelId]);

  return {
    modelAndAudience,
    setModelAndAudience,
    isModelAndAudienceLoading: preferencesResult.isLoading
  };
};

import { Button } from '@mui/material';
import { useShowBriefConfigModal } from 'features/aiTester/modals/briefConfig/useShowBriefConfigModal';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const BriefConfigButton = (): React.ReactElement => {
  const showBriefModal = useShowBriefConfigModal();

  return (
    <Button
      className={mkTesterTourClassName('comparison', 1)}
      color="primary"
      onClick={() => showBriefModal()}
    >
      <FormattedMessage id="testing.brief" />
    </Button>
  );
};

export default BriefConfigButton;

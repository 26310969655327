import { ListItemIcon, ListItemText } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { BrandVoiceOption } from 'features/brand-voice/components/AutocompleteDropdownMenuContent';
import { BrandVoiceName } from 'features/brand-voice/components/BrandVoiceName';
import { BrandVoiceThumbnailAvatar } from 'features/brand-voice/components/BrandVoiceThumbnailAvatar';
import styled from 'styled-components';

type Props = {
  option: BrandVoiceOption;
};

export const BrandVoiceMenuItemContent = ({ option }: Props) => (
  <>
    <ListItemIcon>
      <BrandVoiceThumbnailAvatar thumbnailUrl={option.thumbnailUrl} />
    </ListItemIcon>

    <ListItemText>
      <OptionText>
        <BrandVoiceName name={option.name} />

        {option?.brand?.name && (
          <BrandName variant="body2" color="textDisabled">
            {option.brand.name}
          </BrandName>
        )}
      </OptionText>
    </ListItemText>
  </>
);

const OptionText = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};
`;

const BrandName = styled(ColoredTypography)`
  margin-left: ${({ theme }) => theme.spacings.two};
`;

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type CustomModalProps = {
  headingMessage: ReactNode;
  explanationMessage: ReactNode;
  onAccept: () => void;
  onCancel?: () => void;
};

type Props = CloseModal & CustomModalProps;

export const ChatResetModal = ({
  closeModal,
  onAccept,
  onCancel,
  explanationMessage,
  headingMessage
}: Props) => {
  const handleAcceptClick = () => {
    onAccept();
    closeModal();
  };

  const handleCancelClick = () => {
    onCancel?.();
    closeModal();
  };

  return (
    <Root>
      <Typography variant="h5">{headingMessage}</Typography>
      <Typography>{explanationMessage}</Typography>

      <Footer>
        <Button onClick={handleCancelClick}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={handleAcceptClick}>
          <FormattedMessage id="common.confirm" />
        </Button>
      </Footer>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

import { Typography } from '@mui/material';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

export const PersonalityOrBrandVoiceHeaderWrapper = ({ children }: Props) => (
  <Root>
    <Header>
      <PersonalityIcon />
      <Typography variant="subtitle2">
        <FormattedMessage id="common.brandVoice" />
      </Typography>
      <HelpLinkingButton link="aiWriter.inspirations.aiwriter.main.personality.help_link" />
    </Header>

    {children}
  </Root>
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.one};
`;

const Header = styled.div`
  display: flex;

  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};
`;

import { Typography } from '@mui/material';
import { BrandVoiceName } from 'features/brand-voice/components/BrandVoiceName';
import { BrandVoiceThumbnailAvatar } from 'features/brand-voice/components/BrandVoiceThumbnailAvatar';
import { MouseEvent, ReactNode } from 'react';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import styled from 'styled-components';

type Props = {
  brandVoices: ModelsBrandVoice[];
  selectedId: string;
  defaultMessage?: ReactNode;
  onClick?: (event: MouseEvent) => void;
};

export const BrandVoiceSelectRenderValue = ({
  brandVoices,
  selectedId,
  defaultMessage,
  onClick
}: Props) => {
  const brandVoice = brandVoices.find(brandVoice => brandVoice.id === selectedId);
  if (!brandVoice) {
    return <span onClick={onClick}>{defaultMessage}</span>;
  }

  return (
    <Root onClick={onClick}>
      <BrandVoiceThumbnailAvatar thumbnailUrl={brandVoice.thumbnailUrl} size={18} />

      <NameLabel>
        <BrandVoiceName name={brandVoice.name} />
      </NameLabel>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.two};
`;

const NameLabel = styled(Typography)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  letter-spacing: 0.46px;
`;

import { useModal } from 'components/modals';
import BriefConfigModal from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import { ComponentProps } from 'react';

export const useShowBriefConfigModal = () => {
  const { showModal } = useModal();

  return (props?: Partial<ComponentProps<typeof BriefConfigModal>>) =>
    showModal({
      modalProps: {
        size: 460
      },
      modalType: 'TESTING_BRIEF_MODAL',
      ...props
    });
};

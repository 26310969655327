import { CommandTemplatesButton } from 'features/aiWriter/AiWriterSidebar/steps/chat/CommandTemplatesButton';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import { useUpdateCommandTemplateUsage } from 'features/aiWriter/commandTemplates/useUpdateCommandTemplateUsage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { MouseEvent } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';

type Props = {
  onMouseDown?: (e: MouseEvent) => void;
  modelId: string;
  onSelection: (template: string) => void;
};

export const TemplateSelect = ({ modelId, onSelection, onMouseDown }: Props) => {
  const showTemplatesModal = useShowTemplatesModal();
  const { mutate: updateCommandTemplateUsage } = useUpdateCommandTemplateUsage();

  function handleSelectTemplate() {
    trackingWrapper.track('templatesOpenedViaChat');
    showTemplatesModal({
      preselectedModelId: modelId,
      onTemplateSelect: dto => {
        trackingWrapper.track('templateSelected', {
          templateId: dto.id,
          templateLabel: dto.title,
          templateCountry: dto.country,
          templateLanguage: dto.language
        });

        updateCommandTemplateUsage({ templateId: dto.id });
        onSelection(dto.template);
        GAEvents.promptInChatSent();
      }
    });
  }

  return (
    <CommandTemplatesButton
      gtmId={gtmIds.aiWriter.projectOverview.chat.openPromptLibrary}
      onSelection={handleSelectTemplate}
      onMouseDown={onMouseDown}
    />
  );
};

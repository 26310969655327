import { useMutation } from '@tanstack/react-query';
import {
  getCurrentGptModel,
  syncStore
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { invalidateActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import {
  CreateConversationProps,
  GptModel,
  httpCreateConversation
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { useAppSelector } from 'store/hooks';
import { createUuidV4 } from 'utils/createUuidV4';

type CallbackOptions = {
  personalityId: number | null;
  brandVoiceId: string | null;
  gptModel: GptModel | null;

  callback?: () => void;
};

export const useResetChat = () => {
  const { id: projectId } = useAppSelector(getActiveTab);

  const { mutate } = useMutation({
    mutationFn: (props: CreateConversationProps) => httpCreateConversation.callEndpoint(props),
    onSuccess: (data, { brandVoiceId, gptModel }) => {
      if (!data) {
        return;
      }

      const { conversation_id: conversationId } = data;
      const currentGptModel = getCurrentGptModel();
      invalidateActiveConversationQuery({ projectId });

      syncStore({
        projectId,
        conversationId,
        brandVoiceId,
        gptModel: gptModel ?? currentGptModel ?? null,
        suggestions: []
      });
    }
  });

  return ({ personalityId, brandVoiceId, gptModel, callback }: CallbackOptions) => {
    const conversationId = createUuidV4();

    mutate({
      projectId,
      conversationId,
      personalityId,
      brandVoiceId,
      gptModel
    });

    callback?.();
  };
};

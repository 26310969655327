import { useQuery, useQueryClient } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { getIsAuthenticated, getUserId } from 'features/customer/store/selectors';
import { httpGetWorkspaceMembers } from 'services/backofficeIntegration/http/endpoints/workspace/httpGetWorkspaceMembers';
import { useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

const getQueryKey = () => [...makeCurrentUserQueryKeyPrefix(), 'workspaceId'];

export const useGetWorkspaceId = () => {
  const customerId = useAppSelector(getUserId);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  return useQuery({
    enabled: isAuthenticated && !!customerId,
    queryKey: getQueryKey(),
    queryFn: async () => {
      assertNonNullable(customerId);

      // This is a list (a workspace member entity) for every workspace the user is a member of
      const myWorkspaceMembershipEntries = await httpGetWorkspaceMembers.callEndpoint({
        customerId
      });

      // If there's only one, use it
      if (myWorkspaceMembershipEntries.length === 1) {
        return myWorkspaceMembershipEntries[0].workspaceId;
      }

      // In case of multiple, use the first there the customer is NOT the owner
      return (
        myWorkspaceMembershipEntries.find(member => member.role !== 'OWNER')?.workspaceId || null
      );
    },
    staleTime: 1000 * 60 * 5 // 5 minutes
  });
};

export const useInvalidateWorkspaceIdQuery = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: getQueryKey() });
};

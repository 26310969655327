import { Link, Skeleton, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as IconChat } from 'assets/icons/ai-writer-sidebar/icon-chat-flash.svg';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { predefineMessageDraft } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { allowedPricingPlans } from 'features/aiWriter/chat/GptSelect';
import { HomeChatLanguageSelector } from 'features/aiWriter/chat/HomeChatLanguageSelector';
import { HomeMessageInput } from 'features/aiWriter/chat/HomeMessageInput';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { setCurrentTab } from 'features/aiWriter/store/actions/tabs/actions';
import { configureChatTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureChatTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { useGetDefaultBrandVoice } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useModelAndAudience } from 'features/homePage/chatForm/useModelAndAudience';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { fetchDefaultOutputTypeByLanguageCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';
import { withTestId } from 'utils/utils';

export type MessageConfig = {
  text: string;
  personality?: PersonalityDto | null;
  brandVoiceId?: string | null;
  gptModel: GptModel;
  informationList?: InformationDto[];
};

export const aiWriterWithoutNewDocumentParameterName = 'skipNewDocument';

export const CreateChatForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const pricingPlan = useAppSelector(getPricingPlan);

  const { modelAndAudience, setModelAndAudience, isModelAndAudienceLoading } =
    useModelAndAudience();

  const getModelAudiences = useGetModelAudiences();

  const storedGptModel =
    (localStorage.getItem(LocalStorageKey.DefaultGptModel) as GptModel) ?? GPT_MODELS.GPT_3_5;

  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);

  const initGptModel = isAllowedToSelectGpt4 ? storedGptModel : GPT_MODELS.GPT_3_5;

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(
    modelAndAudience?.modelId
  );

  const { data: defaultBrandVoice, isInitialLoading: isLoadingDefaultBrandVoice } =
    useGetDefaultBrandVoice({
      language,
      country
    });

  const isLoadingInitialData = isModelAndAudienceLoading || isLoadingDefaultBrandVoice;

  const initialMessageConfig: MessageConfig = useMemo(
    () => ({
      text: '',
      gptModel: initGptModel,
      brandVoiceId: defaultBrandVoice?.brandVoiceId ?? null
    }),
    [defaultBrandVoice?.brandVoiceId, initGptModel]
  );

  const [messageConfig, setMessageConfig] = useState<MessageConfig>(initialMessageConfig);

  // Update the initial configuration
  useEffect(() => {
    setMessageConfig(initialMessageConfig);
  }, [initialMessageConfig]);

  const { mutate: initializeTab, isLoading: isSending } = useMutation({
    mutationFn: async (embeddingModelId: string) => {
      const audience = getModelAudiences(embeddingModelId)[0];
      assertNonNullable(audience.language, 'Audience language falsy');
      assertNonNullable(audience.country, 'Audience country falsy');

      const outputType = await fetchDefaultOutputTypeByLanguageCountry(
        audience.language,
        audience.country
      );

      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId: embeddingModelId,
            audienceId: audience.id,
            name: unnamed,
            isNewDocument: true,
            outputType,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            brandVoiceId: messageConfig.brandVoiceId ?? undefined
          },
          configureChatTabThunk,
          { shouldCollapseSidebar: false }
        )
      );
    }
  });

  const handleSendMessage = async (text: string) => {
    const { gptModel, brandVoiceId, informationList } = messageConfig;
    if (!modelAndAudience) {
      return;
    }

    localStorage.setItem(LocalStorageKey.DefaultGptModel, gptModel);

    // this is required to reset the current project
    // in order to start a new chat rather than send a message to an old one
    dispatch(setCurrentTab(''));

    await navigate(`${getRoutePath('aiWriter')}?${aiWriterWithoutNewDocumentParameterName}=true`);

    predefineMessageDraft({
      text,
      gptModel,
      brandVoiceId: brandVoiceId ?? null,
      informationList: informationList
    });

    const embeddingModelId = modelAndAudience?.modelId;
    initializeTab(embeddingModelId);
  };

  const handleFormSubmit = (embeddingModelId: string) => {
    const audiences = getModelAudiences(embeddingModelId);
    const audienceId = getAudienceInitValue(audiences, LocalStorageKey.AiWriterProject) ?? 0;

    setModelAndAudience({ modelId: embeddingModelId, audienceId });
  };

  return (
    <Root>
      <Header {...withTestId('chatflash-element')}>
        <FlexContainer direction="row" gap="two" alignItems="center">
          <IconContainer>
            <IconChat />
          </IconContainer>
          <StyledTypography variant="body1">
            <FormattedMessage id="home.chatFlash.title" />
          </StyledTypography>
        </FlexContainer>
        {isLoadingInitialData || !modelAndAudience ? (
          <Skeleton variant="rounded" width={120} height={31} />
        ) : (
          <HomeChatLanguageSelector
            embeddingModels={models}
            selectedEmbeddingModelId={modelAndAudience?.modelId}
            onSelect={handleFormSubmit}
            size="small"
            isSaveAsDefaultShown={true}
            customTextFieldStyles={{
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important'
              }
            }}
            customAutocompleteStyles={{
              '.MuiAutocomplete-endAdornment': { display: 'none' }
            }}
          />
        )}
      </Header>
      {isLoadingInitialData || !modelAndAudience ? (
        <TextAreaSkeleton variant="rounded" width="100%" height={147} />
      ) : (
        <HomeMessageInput
          modelId={modelAndAudience.modelId}
          sendButtonConfig={{
            gtmId: gtmIds.aiWriter.projectOverview.chat.sendMessage
          }}
          isSending={isSending}
          value={messageConfig}
          onChange={message => setMessageConfig(message)}
          onSend={handleSendMessage}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacings.seven}`};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;

const StyledTypography = styled(Typography)`
  line-height: 0;
`;

export const StyledTooltipLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryColorLight};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorLight};
  }
` as typeof Link;

const TextAreaSkeleton = styled(Skeleton)`
  // Matches border radius of the text field
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;

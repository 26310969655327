import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CircularProgress, InputAdornment } from '@mui/material';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
};

export const TextFieldEndAdornment = ({ isOpen, isLoading }: Props) => {
  if (isLoading) {
    return (
      <InputAdornment position="end">
        <CircularProgress size={20} />
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="end">
      {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </InputAdornment>
  );
};

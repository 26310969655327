import { Edit, Favorite, FavoriteBorder } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  LinearProgress,
  ListItemIcon,
  MenuList,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import {
  ExecuteHint,
  FlashTitle,
  StyledIconButton
} from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsMenu';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { StyledMenuItem, StyledPaper } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { setFlashActionsSelectedAction } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useMemo } from 'react';
import { httpFavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpFavoriteTemplate';
import { httpUnfavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpUnfavoriteTemplate';
import {
  SortingTypes,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { useAppSelector } from 'store/hooks';

type Props = {
  searchQuery: string;
  highlightedMenuIndex: number;

  onClose: () => void;
  handleMouseOptionHover: (index: number) => void;
  onOptionSelect: () => void;
};

export function CustomFlashActionsSearch(props: Props) {
  const customerId = useAppSelector(state => state.customer.id);
  const { data: categories } = useTemplateCategoriesQuery();

  const flashActionsId = useMemo(
    () => categories?.filter(category => category.alias === flashActionsCategoryAlias)[0].id,
    [categories]
  );

  const customFlashActions = useTemplatesQuery({
    category: flashActionsId,
    title: props.searchQuery.trim().length > 0 ? props.searchQuery : undefined,
    sorting: SortingTypes.FAVORITES
  });

  const { showModal } = useModal();

  const { mutate: favoriteTemplate, isLoading: isFavoriteLoading } = useMutation({
    mutationFn: httpFavoriteTemplate.callEndpoint,
    onSuccess: () => customFlashActions.refetch()
  });
  const { mutate: unfavoriteTemplate, isLoading: isUnfavoriteLoading } = useMutation({
    mutationFn: httpUnfavoriteTemplate.callEndpoint,
    onSuccess: () => customFlashActions.refetch()
  });

  const isFavoriteActionLoading = isFavoriteLoading || isUnfavoriteLoading;

  const handleCheckboxChange = (checked: number, template: TemplateDto) => {
    if (checked) {
      unfavoriteTemplate({ templateId: template.id });
      return;
    }

    favoriteTemplate({ templateId: template.id });
  };

  const handleEditAction = (template: TemplateDto) => {
    props.onClose();
    showModal('FLASH_ACTIONS', {
      modalMode: 'edit',
      categoryId: flashActionsId,
      template,
      size: 600
    });
  };

  const renderCustomFlashActionOptions = () => {
    if (customFlashActions.isLoading) {
      return (
        <FlexContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      );
    }

    if (!customFlashActions.data) {
      return null;
    }

    const favoritesList = customFlashActions.data.pages.map(page => page.data).flat(1);

    if (favoritesList.length === 0 && props.searchQuery !== '') {
      return (
        <ExecuteHint>
          <Typography variant="body2">
            <FormattedMessage id="aiWriter.flash_actions.search.no_results.label" />
          </Typography>
        </ExecuteHint>
      );
    }

    return favoritesList?.map((action, index) => (
      <StyledMenuItem
        $showHover={index === props.highlightedMenuIndex}
        onMouseEnter={() => props.handleMouseOptionHover(index)}
        key={Math.random()}
        onClick={e => {
          e.stopPropagation();
          setFlashActionsSelectedAction({
            name: 'customActions',
            prePrompt: action.template
          });
          props.onOptionSelect();
        }}
        disabled={isFavoriteActionLoading || customFlashActions.isFetching}
      >
        <ListItemIcon
          onClick={e => {
            e.stopPropagation();
            handleCheckboxChange(action.is_liked, action);
          }}
        >
          {action.is_liked ? (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.unfavorite" />}>
              <Favorite fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.favorite" />}>
              <FavoriteBorder fontSize="small" />
            </Tooltip>
          )}
        </ListItemIcon>
        <FlexContainer
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <FlashTitle>{action.title}</FlashTitle>
          {customerId === action.author_id && (
            <StyledIconButton
              onClick={e => {
                e.stopPropagation();
                handleEditAction(action);
              }}
            >
              <Tooltip title={<FormattedMessage id="common.edit" />}>
                <Edit fontSize="small" color="action" />
              </Tooltip>
            </StyledIconButton>
          )}
        </FlexContainer>
      </StyledMenuItem>
    ));
  };

  function renderCustomFlashActionsQueryControls() {
    if (customFlashActions.isFetching && customFlashActions.data) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (customFlashActions.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            customFlashActions.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  return (
    <StyledPaper elevation={1}>
      <MenuList>
        {renderCustomFlashActionOptions()}
        {renderCustomFlashActionsQueryControls()}
      </MenuList>
    </StyledPaper>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getUserId } from 'features/customer/store/selectors';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { httpGetDefaultBrandVoices } from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpGetDefaultBrandVoices';
import { useAppSelector } from 'store/hooks';

type Options = {
  language: string;
  country: string;
};

export const useGetDefaultBrandVoice = (options?: Options) => {
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const filter = {
    country: options?.country || currentModelCountry,
    language: options?.language || currentModelLanguage
  };

  const { data: brandVoices, ...rest } = useGetAllDefaultBrandVoices();

  const matchingBrandVoice = brandVoices?.find(
    brandVoice => brandVoice.language === filter.language && brandVoice.country === filter.country
  );

  return {
    data: matchingBrandVoice,
    ...rest
  };
};

export const useGetAllDefaultBrandVoices = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const customerId = useAppSelector(getUserId);

  return useQuery({
    enabled: !!workspaceId && !!customerId,
    queryKey: ['defaultBrandVoices', workspaceId, customerId],
    queryFn: () => {
      if (!workspaceId) {
        throw new Error('workspaceId is required');
      }

      const params = {
        workspaceId,
        customerId
      };

      return httpGetDefaultBrandVoices.callEndpoint(params);
    }
  });
};

type OptionsWithoutCache = {
  workspaceId: string;
  customerId: number;
  language: string;
  country: string;
};

export const getDefaultBrandVoiceWithoutCache = async (options: OptionsWithoutCache) => {
  const brandVoices = await httpGetDefaultBrandVoices.callEndpoint({
    workspaceId: options.workspaceId,
    customerId: options.customerId
  });

  const matchingBrandVoice = brandVoices?.find(
    brandVoice => brandVoice.language === options.language && brandVoice.country === options.country
  );

  return matchingBrandVoice ?? null;
};

import { PushPin } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { ReactComponent as SetPinIcon } from 'assets/set-pin-icon.svg';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { syncStore, useIsChatEmpty } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { invalidateActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import {
  changeGenerateTextConfig,
  changeProjectSettings
} from 'features/aiWriter/store/actions/config/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { getDefaultBrandVoiceWithoutCache } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getUserId } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import {
  getEmbeddingModelsByLanguageAndAudience,
  getGetEmbeddingModelById,
  getGetEmbeddingModelLanguageAndCountryById
} from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { MouseEvent, useMemo } from 'react';
import {
  GPT_MODELS,
  GptModel,
  httpCreateConversation
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { createUuidV4 } from 'utils/createUuidV4';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { assertNonNullable, forceNonNullable } from 'utils/typescript/nonNullable';

type Props = MenuProps & {
  handleLanguageCustomClick?: (language: string) => void;
  isSaveAsDefaultShown?: boolean;
};

export const LanguageMenu = ({
  handleLanguageCustomClick,
  isSaveAsDefaultShown,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const { id: projectId, embeddingModelId, sharingPermission } = useAppSelector(getActiveTab);
  const getEmbeddingModel = useAppSelector(getGetEmbeddingModelById);
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const getModelLanguageAndCountryById = useAppSelector(getGetEmbeddingModelLanguageAndCountryById);
  const isChatEmpty = useIsChatEmpty();
  const preferencesQuery = useCustomerPreferences();
  const { data: preferences } = preferencesQuery;
  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();
  const defaultLanguageModelId = models.find(
    model =>
      model.language === preferences?.preferredLanguage &&
      model.country === preferences?.preferredLanguageCountry
  )?.id;

  const warnAboutChatReset = useWarnAboutChatReset();

  const getCountryProjects = useGetModelAudiences();

  const [storedGptModel] = useLocalStorage<GptModel>(
    LocalStorageKey.DefaultGptModel,
    GPT_MODELS.GPT_3_5
  );

  const sortedModels = useMemo(() => sortModelsByLanguageAlphabetically(models), [models]);

  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const customerId = useAppSelector(getUserId);

  const handleModelChange = async (
    event: MouseEvent,
    newEmbeddingModelId: EmbeddingModel['id']
  ) => {
    const { country, language } = forceNonNullable(
      getEmbeddingModel(newEmbeddingModelId),
      `Model not found: ${newEmbeddingModelId}`
    );
    // Prevents the menu from closing when the user clicks on the currently selected model
    // But don't return if we have a custom handler for language change
    if (embeddingModelId === newEmbeddingModelId && !handleLanguageCustomClick) {
      if (
        preferences?.preferredLanguage !== language ||
        preferences?.preferredLanguageCountry !== country
      ) {
        // closes the menu when user wants to update his preferred language to a doc language value
        props?.onClose?.(event, 'backdropClick');
      }
      return;
    }

    // Close the menu to prevent showing the menu and a modal at the same time
    props?.onClose?.(event, 'backdropClick');

    // allows us to pass a custom handler for language change that allows this component to be reused in other places
    if (handleLanguageCustomClick) {
      const { language, country } = getModelLanguageAndCountryById(newEmbeddingModelId);
      handleLanguageCustomClick(`${language}_${country}`);
      return;
    }

    if (!isChatEmpty) {
      warnAboutChatReset({
        onAccept: () => {
          resetChat({ country, language }).then(() => {
            updateSettings({ newEmbeddingModelId });
          });
        }
      });
      return;
    } else {
      assertNonNullable(workspaceId, 'workspaceId is required');

      const brandVoice = await getDefaultBrandVoiceWithoutCache({
        country,
        language,
        workspaceId,
        customerId
      });
      const brandVoiceId = brandVoice?.brandVoiceId ?? null;

      dispatch(
        changeGenerateTextConfig({
          brandVoiceId: brandVoiceId ?? undefined
        })
      );
    }
    // if chat is empty, we can change the model without any warnings
    updateSettings({ newEmbeddingModelId });
  };

  const updateSettings = ({ newEmbeddingModelId }: { newEmbeddingModelId: string }) => {
    const countryProjects = getCountryProjects(newEmbeddingModelId);
    const newAudienceId = getAudienceInitValue(countryProjects, LocalStorageKey.AiWriterProject);

    assertNonNullable(newAudienceId, `Audience for model not found: ${newEmbeddingModelId}`);

    localStorage.setItem(LocalStorageKey.AiWriterModel, String(newEmbeddingModelId));

    dispatch(
      changeProjectSettings({
        embeddingModelId: newEmbeddingModelId,
        sharingPermission,
        audienceId: newAudienceId
      })
    );
    dispatch(updateCurrentProjectInBackgroundThunk());
  };

  const handleSetPreferredLanguage = (value: EmbeddingModel) => {
    const { language, country } = getModelLanguageAndCountryById(value.id);
    updatePreferences({ preferredLanguage: language, preferredLanguageCountry: country });
    localStorage.setItem(LocalStorageKey.AiWriterModel, String(value.id));
  };

  const resetChat = async (props: { country: string; language: string }) => {
    assertNonNullable(workspaceId, 'workspaceId is required');

    const brandVoice = await getDefaultBrandVoiceWithoutCache({
      country: props.country,
      language: props.language,
      workspaceId,
      customerId
    });
    const brandVoiceId = brandVoice?.brandVoiceId ?? null;
    const conversationId = createUuidV4();

    await httpCreateConversation.callEndpoint({
      projectId,
      conversationId,
      brandVoiceId,
      gptModel: storedGptModel
    });

    dispatch(
      changeGenerateTextConfig({
        brandVoiceId
      })
    );
    invalidateActiveConversationQuery({ projectId });
    syncStore({ projectId, conversationId, brandVoiceId, gptModel: storedGptModel });
  };

  return (
    <StyledMenu {...props}>
      {sortedModels.map(model => (
        <StyledMenuItem
          key={`${model.country}-${model.language}`}
          onClick={event => handleModelChange(event, model.id)}
          selected={embeddingModelId === model.id}
        >
          <FlexContainer direction="row" alignItems="center">
            <ListItemIcon>
              <CountryFlag country={model.country} language={model.language} />
            </ListItemIcon>

            {model.label}
          </FlexContainer>
          {isSaveAsDefaultShown &&
            (defaultLanguageModelId && defaultLanguageModelId === model.id ? (
              <StyledPin fontSize="small" />
            ) : (
              (embeddingModelId !== model.id || model.id !== defaultLanguageModelId) && (
                <StyledSetPin
                  fontSize="small"
                  className="pinIcon"
                  onClick={() => handleSetPreferredLanguage(model)}
                />
              )
            ))}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

const StyledPin = styled(PushPin)`
  &,
  > svg {
    color: ${({ theme }) => theme.colors.blackInactive};
  }
`;

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;

  .pinIcon {
    display: none;
  }

  &:hover {
    .pinIcon {
      display: block;
    }
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    min-width: 15rem;
  }
`;

const StyledSetPin = styled(SetPinIcon)`
  position: absolute;
  right: 1rem;
`;
